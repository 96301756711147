<script setup lang="ts">
const props = defineProps({
  variant: {
    type: String as PropType<"filled" | "outline">,
    default: "filled",
  },
})

const styles = {
  variant: {
    filled: "bg-[--theme-medium] text-white",
    outline:
      "bg-transparent border-[--theme-dark] border-2 text-[--theme-dark]",
  },
}
</script>

<template>
  <span
    class="h-fit w-fit font-national2 font-bold italic !leading-5 inline-flex"
    :class="[styles.variant[props.variant]]"
  >
    <slot />
  </span>
</template>
