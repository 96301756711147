import { escapeRegExp } from "lodash-es"
import { getArticleByLocationId } from "~/api/getArticles"
import type { Article } from "~/types/article"
import type {
  SearchResultWrapper,
  SearchResult,
} from "~/types/search"

const LIMIT = 10
let lastCountQuery: string

function formatTitle(query: string, title: string) {
  return title.replaceAll(
    new RegExp(escapeRegExp(query), "gi"),
    "<mark class='bg-[--theme-translucent-light]' style='color: inherit; font: inherit'>$&</mark>",
  )
}

function formatDescription(query: string, description: string) {
  if (!description.trim()) return ""

  const safeDesc = new DOMParser().parseFromString(
    description.replaceAll("<", "&lt;").replaceAll(">", "&gt;"),
    "text/html",
  ).body.innerHTML

  // Because of a spec bug, we can't reuse regexes
  // Ref: https://stackoverflow.com/questions/3891641/regex-test-only-works-every-other-time/3891672#3891672
  const createRegex = () => new RegExp(escapeRegExp(query), "gi")

  if (!createRegex().test(safeDesc) || query.length < 1) {
    return `${safeDesc.split(".").slice(0, 2).join(".")}. [...]`
  }

  return `[...] ${safeDesc
    .split(".")
    .filter(sent => createRegex().test(sent))
    .join(". [...] ")
    .replaceAll(
      createRegex(),
      "<mark class='bg-[--theme-translucent-light]' style='color: white; font: inherit'>$&</mark>",
    )}. [...]`
}

async function formatResult(
  query: string,
  data: SearchResultWrapper,
) {
  const hits = data.View?.Result?.searchHits?.searchHit || []
  const articles = await Promise.all(
    hits.map((r: SearchResult) =>
      getArticleByLocationId(r.value.Location.id),
    ),
  )
  const filteredArticles = articles.filter(
    article => article !== null,
  ) as Article[]
  return filteredArticles.map(article => ({
    id: article.id,
    article,
    title: formatTitle(query, article.title),
    description: formatDescription(
      query,
      article.body?.plaintext ?? "",
    ),
  }))
}

export function useContentSearch() {
  const products = useProductVariantsStore()
  const query = ref("")
  const templateIdFilter = ref<number[]>([])
  const locationIdFilter = ref([])
  const loading = ref(0)
  const results = reactive({
    data: <any[]>[],
    count: {
      total: 0,
      filteredTotal: 0,
      locations: new Map(),
      templates: new Map(),
    },
  })

  let controller: null | AbortController = null

  function count() {
    resetGlobalCount()
    lastCountQuery = query.value
    return api.ibexaApi
      .post(
        "/views",
        {
          ViewInput: {
            identifier: "global_search_count",
            LocationQuery: {
              Filter: {
                AND: {
                  ...(query.value
                    ? { FullTextCriterion: query.value + "~" }
                    : {}),
                  ContentTypeIdentifierCriterion: "article",
                  SubtreeCriterion:
                    products.activeVariant?.pathString,
                },
              },
              limit: 1,
              offset: 0,
              Aggregations: [
                {
                  LocationChildrenTermAggregation: {
                    name: "location",
                  },
                },
                {
                  SelectionTermAggregation: {
                    contentTypeIdentifier: "article",
                    fieldDefinitionIdentifier: "template",
                    name: "template",
                  },
                },
              ],
            },
          },
        },
        {
          signal: controller?.signal,
        },
      )
      .then(({ data }) => {
        if (data === null) return Promise.resolve()

        results.count.total = data.View?.Result?.count ?? 0
        const aggregations = data.View?.Result?.aggregations

        for (const { entries, name } of aggregations ?? []) {
          for (const { key, count } of entries) {
            switch (name) {
              case "location": {
                results.count.locations.set(key.Location.id, count)
                break
              }

              case "template": {
                if (
                  [
                    "text",
                    "image with text",
                    "text with image",
                  ].includes(key)
                ) {
                  results.count.templates.set(
                    "text",
                    (results.count.templates.get("text") ?? 0) +
                      count,
                  )
                } else {
                  results.count.templates.set(key, count)
                }

                break
              }
            }
          }
        }
      })
  }

  // Assuming LIMIT is defined somewhere globally
  const DEFAULT_LIMIT = LIMIT // Use a more descriptive constant name

  // Extract the logic for creating the Field filter based on templateId
  function createTemplateIdFilter(templateIdFilter: any[]) {
    return {
      Field: {
        name: "template",
        operator: "IN",
        value: templateIdFilter
          .map(id => (id === 0 ? [0, 4, 5] : [id])) // Handling the "Text" template logic
          .flat(),
      },
    }
  }

  // Extract the logic for creating the OR filter based on locationId
  function createLocationIdFilter(locationIdFilter: any[]) {
    return {
      OR: {
        ParentLocationIdCriterion: locationIdFilter,
      },
    }
  }

  function post({ offset = 0, limit = DEFAULT_LIMIT } = {}) {
    // Recount if the query has changed
    if (query.value !== lastCountQuery) {
      count()
    }

    // Building the Filter object dynamically
    const filters: any = {
      AND: {
        ContentTypeIdentifierCriterion: "article",
        SubtreeCriterion: products.activeVariant?.pathString,
      },
    }

    if (query.value) {
      filters.AND.FullTextCriterion = query.value + "~"
    }
    if (templateIdFilter.value.length > 0) {
      Object.assign(
        filters.AND,
        createTemplateIdFilter(templateIdFilter.value),
      )
    }
    if (locationIdFilter.value.length > 0) {
      Object.assign(
        filters,
        createLocationIdFilter(locationIdFilter.value),
      )
    }

    // The request payload
    const payload = {
      ViewInput: {
        identifier: "global_search",
        LocationQuery: {
          Filter: filters,
          limit,
          offset,
          SortClauses: {
            LocationPath: "ascending",
          },
        },
      },
    }

    return api.ibexaApi.post("/views", payload, {
        signal: controller?.signal,
      })
  }

  function resetGlobalCount() {
    results.count.total = 0
    results.count.locations = new Map()
    results.count.templates = new Map()
  }

  function next() {
    loading.value++

    const offset = results.data.length

    return post({ offset })
      .then(({ data }) =>
        data === null ? [] : formatResult(query.value, data),
      )
      .then(data => {
        results.data.splice(offset, data.length, ...data)
      })
      .finally(() => {
        loading.value--
      })
  }

  function submit() {
    loading.value++
    results.data = []

    if (controller !== null) {
      controller.abort()
    }

    controller = new AbortController()

    return post()
      .then(({ data }) => {
        if (data === null) return Promise.resolve()

        results.count.filteredTotal = data.View?.Result?.count ?? 0

        return formatResult(query.value, data).then(articles => {
          results.data = results.data.concat(articles)
        })
      })
      .finally(() => {
        loading.value--
      })
  }

  return {
    loading,
    query,
    templateIdFilter: templateIdFilter,
    locationIdFilter,
    results,
    submit,
    next,
  }
}
