<template>
  <div>
    <teleport to="#root">
      <div class="z-5 fixed left-0 top-0 mx-auto w-full">
        <div
          :key="1"
          class="z-10 flex h-[calc(100vh-0rem)] items-center justify-center bg-[--theme-darkest]"
        >
          <div
            class="flex flex-col items-center justify-center gap-y-5"
          >
            <u-book-loader />
            <p class="font-national2 text-2xl text-white">
              {{ "Laster inn.." }}
            </p>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>
