<script setup lang="ts">
import type { Article } from "~/types/article"
import { keys } from "~/utilities/injection"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const textClasses = computed(() => {
  const start = 2
  const end = 16 - 3

  return `col-start-2 col-end-18 @md:col-start-${start} @md:col-end-${end} @lg:col-start-${
    start + 1
  } @lg:col-end-${end - 1} @2xl:col-start-${start} @2xl:col-end-${
    end - 1
  }`
})

defineExpose({
  SIDENOTE_OFFSET: 0.5,
})

const assignmentType = computed(
  () => props.data.mathTask?.assignmentType,
)

provide(keys.mathTaskSolutionVideo, toRef(props.data.video))
</script>

<template>
  <UArticle>
    <UArticleHeader
      :data="data"
      class="col-start-2 col-end-18 break-words pt-[var(--grid-col-width)] @md:col-start-5 @md:col-end-15"
    >
      <template #contentTag>
        <UContentBadge
          v-if="assignmentType"
          :content="assignmentType"
          :aria-label="`Oppgavetype: ${assignmentType}`"
          :icon="
            assignmentType === 'Mengdetrening' ? 'repeat' : undefined
          "
        />
      </template>
    </UArticleHeader>
    <div
      class="col-start-2 col-end-18 mb-32 flex flex-col gap-8 rounded-lg bg-white px-4 pt-5 @md:col-start-5 @md:col-end-15 lg:rounded-2xl lg:p-12"
    >
      <section
        class="flex flex-col gap-8 break-words text-[18px] md:text-[24px] [&>*]:font-national2"
      >
        <h2
          v-if="data.intro?.html || data.body?.html"
          class="sr-only"
        >
          Oppgaveintroduksjon
        </h2>
        <u-rich-text
          class="text-mobile-math-article-intro md:text-desktop-math-article-intro"
          :class="textClasses"
          :html="data.intro?.html"
        />
        <u-rich-text :html="data.body?.html" />
        <h2 class="sr-only">Oppgaver</h2>
        <UMathTaskAssignments />
      </section>
      <UMathTaskActions />
    </div>
  </UArticle>
</template>
