<script setup lang="ts">
import type { Article } from "~/types/article"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

defineExpose({
  SIDENOTE_OFFSET: 0.5,
})
const imageSize = computed(() => {
  switch (props.data.imageSize) {
    case "Small":
      return 12

    case "Medium":
      return 16

    case "Large":
    default:
      return 18
  }
})

const imageClasses = computed(() => {
  const start = 10 - imageSize.value / 2
  const end = 10 + imageSize.value / 2

  return `col-start-1 col-end-19 @md:col-start-${start} @md:col-end-${end}`
})
</script>

<template>
  <UArticle>
    <UArticleHeader
      :data="data"
      class="col-start-2 col-end-18 pt-[var(--grid-col-width)] @md:col-start-5 @md:col-end-15"
    />

    <u-article-image
      v-if="data.img?.src"
      :data="data"
      class="u-article__image u-article__image--inline"
      :class="imageClasses"
    />

    <section
      class="col-start-2 col-end-18 pb-32 @md:col-start-5 @md:col-end-15 flex flex-col gap-10"
    >
      <u-rich-text :html="data.body?.html" />
      <UArticleFiles v-if="data.files" :files="data.files" />
    </section>
  </UArticle>
</template>
