import {
  ProductsMap,
  ProductVariants,
} from "~/models/Content/ContentProducts"
import type { ProductInformation } from "~/models/Content/ContentProducts"
import { ProductPageList } from "~/api/getProductPageList"
import {
  formatList,
  parseInstitutionNameAndCode,
} from "~/utilities/format"
import { formatForURL } from "~/utilities/composables"
import {
  extractProductVariant,
  buildProductKey,
} from "~/utilities/format-product"
import type { ProductStatusList } from "~/types/productData.d"
import type { VariantKeys } from "~/utilities/constants"

export function getGroupedProductVariants(
  productVariants: ProductVariants[] = [],
  productPages: ProductPageList | null | undefined,
): ProductsMap {
  const map: ProductsMap = {}

  for (const productVariant of productVariants) {
    const fullProductTitle = productVariant.ibexaTitle
    const institutionCode = extractProductVariant(fullProductTitle)

    if (!institutionCode) continue

    const productTitle = fullProductTitle.replace(
      ` ${institutionCode}`,
      "",
    )

    const productKey = buildProductKey(productVariant.ibexaTitle)

    const parsedCurriculumInfo = parseInstitutionNameAndCode(
      productVariant.pensumFor,
    )

    // institute is for example "Universitetet i Oslo (UiO)" we want to extract the short name
    // and will return "UiO"
    const institutionAcronyms = formatList(
      productVariant.institute.map(i => {
        const startIndex = i.indexOf("(")
        const endIndex = i.indexOf(")")
        if (startIndex !== -1 && endIndex !== -1) {
          return i.substring(startIndex + 1, endIndex)
        }
        return i
      }),
    )

    const isReleased =
      productVariant.status === "full-release" ||
      productVariant.status === "partial-release"

    const variant: ProductInformation = {
      name: productVariant.ibexaTitle,
      shortTitle: productVariant.shortTitle,
      titleHtml: productVariant.titleHtml,
      title: productVariant.title,
      ean: productVariant.ean,
      curriculumFor: institutionAcronyms,
      isReleased,
      status: productVariant.status,
      variantKey: institutionCode.toLowerCase() as VariantKeys,
      shortVariantLabel: institutionCode,
      locationId: productVariant._location.id,
      pathString: productVariant._location.pathString,
      isFree: productVariant.isFree,
    }

    const status: ProductStatusList = {
      locationId: productVariant._location.id,
      status: productVariant.status,
    }

    const existing = map[productKey]

    if (!existing) {
      const {
        theme,
        header,
        quote,
        blocks,
        uspList,
        groupSalesPitch,
        productGroupStatusDetails,
      } = productPages?.[productKey] ?? {}

      map[productKey] = {
        productKey,
        productTitle,
        productTitleHtml: productVariant.titleHtml,
        urlFormatProductTitle: formatForURL(productTitle),
        hasAtLeastOneReleasedProduct: isReleased,
        productGroupStatus: [status],
        theme,
        header: header?.html5,
        quote,
        blocks,
        uspList,
        groupSalesPitch: groupSalesPitch?.html5,
        productGroupStatusDetails: productGroupStatusDetails,
        productCurriculumFor:
          parsedCurriculumInfo.shortNamesAndCourseCodes,
        vanitySentence: parsedCurriculumInfo.vanitySentence,
        variants: [variant],
      }
    } else {
      map[productKey] = {
        ...existing,
        productCurriculumFor: [
          ...existing.productCurriculumFor,
          ...parsedCurriculumInfo.shortNamesAndCourseCodes,
        ],
        productGroupStatus: [...existing.productGroupStatus, status],
        variants: [...existing.variants, variant],
        vanitySentence: parsedCurriculumInfo.vanitySentence,
        hasAtLeastOneReleasedProduct:
          existing.hasAtLeastOneReleasedProduct || isReleased,
        productTitleHtml:
          existing.productTitleHtml || productVariant.titleHtml,
      }
    }
  }

  return map
}
