export const routes = [
  {
    path: '/',
    name: 'hjem',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:product/search-offline',
    name: 'search-offline',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/[product].search-offline.vue'),
    /* no children */
    meta: {
      "title": "Søk (Offline)",
      "layout": "navigation"
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/404.vue'),
    /* no children */
    meta: {
      "title": 404,
      "layout": 404
    }
  },
  {
    path: '/artikler/:path',
    name: '/artikler',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/artikler.vue'),
    /* no children */
    meta: {
      "title": "Artikler",
      "layout": "marketing"
    }
  },
  {
    path: '/feature-flag',
    name: 'featureFlag',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/feature-flag.vue'),
    /* no children */
    meta: {
      "title": "featureFlag",
      "layout": "basic"
    }
  },
  {
    path: '/om/:path?',
    name: '/om',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/om.vue'),
    /* no children */
    meta: {
      "title": "Om",
      "layout": "marketing"
    }
  },
  {
    path: '/produkter/:product',
    name: 'productPage',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].vue'),
    /* no children */
    meta: {
      "layout": "productPage"
    }
  },
  {
    path: '/produkter/:product/:variant',
    name: 'product',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].vue'),
    /* no children */
    meta: {
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/:subject',
    name: 'subject',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].[subject].vue'),
    /* no children */
    meta: {
      "layout": "subject"
    }
  },
  {
    path: '/produkter/:product/:variant/:subject/:article',
    name: 'article',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].[subject].[article].vue'),
    /* no children */
    meta: {
      "layout": "article",
      "is": "article",
      "authorize": true
    }
  },
  {
    path: '/produkter/:product/:variant/:subject/min-side',
    name: '/produkter.[product].[variant].[subject].min-side',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].[subject].min-side.vue'),
    /* no children */
    meta: {
      "title": "Min side",
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/begreper',
    name: 'begreper',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].begreper.vue'),
    /* no children */
    meta: {
      "title": "Begreper",
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/kategorier',
    name: '/produkter.[product].[variant].kategorier',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].kategorier.vue'),
    /* no children */
    meta: {
      "title": "Kategorier",
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/min-side',
    name: '/produkter.[product].[variant].min-side',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].min-side.vue'),
    /* no children */
    meta: {
      "title": "Min side",
      "layout": "product"
    }
  },
  {
    path: '/produkter/:product/:variant/sok',
    name: 'search',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].[variant].sok.vue'),
    /* no children */
    meta: {
      "title": "Søk",
      "layout": "navigation"
    }
  },
  {
    path: '/produkter/:product/betaling',
    name: 'productCheckout',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].betaling.vue'),
    /* no children */
    meta: {
      "title": "Betaling"
    }
  },
  {
    path: '/produkter/:product/logg-inn',
    name: 'login',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].logg-inn.vue'),
    /* no children */
  },
  {
    path: '/produkter/:product/registrer-interesse',
    name: 'registerInterest',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/produkter.[product].registrer-interesse.vue'),
    /* no children */
  },
  {
    path: '/studentliv/:path',
    name: '/studentliv',
    component: () => import('/builds/aschehoug/universitetsforlaget/kunne-spa/src/pages/studentliv.vue'),
    /* no children */
    meta: {
      "title": "Studentliv",
      "layout": "marketing"
    }
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

