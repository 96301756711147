<script setup lang="ts">
import { checkoutPageUrl } from "~/utilities/urls"
const route = useRoute<"product">()
const productParam = computed(() => route.params.product)

const products = useProductVariantsStore()

const { productPriceFilteredByEAN, data } = useEcommerceCatalogue()

const productPrice = computed(() => {
  const priceData = productPriceFilteredByEAN(
    products.activeVariant?.ean,
  )
  return priceData?.actual || 0
})

const productIsFree = computed(() => {
  if (productPrice.value === 0) {
    return true
  }
  return false
})

const BuyButtonURL = computed(
  () =>
    `${checkoutPageUrl(productParam.value, {
      fromPath: `/produkter/${products.activeProduct?.urlFormatProductTitle}/${products.activeVariant?.variantKey}`,
      variant: products.activeVariant?.shortVariantLabel,
    })}`,
)
</script>
<template>
  <router-link
    v-if="
      data &&
      !productIsFree &&
      !products.activeVariant?.userHasLicence &&
      products.activeVariant?.isReleased
    "
    :to="BuyButtonURL"
    class="flex h-fit min-h-28 w-screen items-center justify-center bg-[--theme-lighter] py-4"
  >
    <div
      class="flex w-[85%] max-w-[90em] items-center justify-between gap-4 font-national2 text-2xl text-[--theme-darker] 2xl:text-3xl"
    >
      <h2
        class="line-clamp-3 max-w-[45%] md:line-clamp-1 md:max-w-[85%] lg:max-w-[75%]"
      >
        <span class="hidden xl:inline">
          Forstå faget og bestå eksamen med Kunne
        </span>
        <span class="xl:hidden">Kunne </span>
        <b>{{ productPrice }},-</b>
        for hele semesteret
      </h2>
      <UButtonNew
        class="md:hidden"
        variant="filled"
        theme="cta"
        pill
        size="lg"
        data-pendo="banner-kjop-tilgang"
        :to="BuyButtonURL"
      >
        Kjøp
        <span class="sr-only">
          tilgang til Kunne {{ productParam }}</span
        >
      </UButtonNew>
      <UButtonNew
        class="hidden md:block"
        variant="filled"
        theme="cta"
        pill
        size="lg"
        :to="BuyButtonURL"
        data-pendo="banner-kjop-tilgang"
      >
        Kjøp tilgang
        <span class="sr-only"> til Kunne {{ productParam }}</span>
      </UButtonNew>
    </div>
  </router-link>
</template>
