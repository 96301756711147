<script setup lang="ts">
import type { Subject } from "~/types/subject"

const props = defineProps<{ chapter: Subject }>()

const { imageType, calculateCardImageStyle } = useChapterCard(props)

const classes = useThemedClasses(() => props.chapter.colorTag)

const cardGradientClass = computed(
  () => imageType.value === "backgroundImage"
    ? [classes.value.gradientImage()]
    : [classes.value.gradient(), classes.value.background()],
)

const mediaCounts = useMediaCounts(() => props.chapter)

const subjectHasContent = computed(() => {
  const counts = mediaCounts.data.value
  return (
    !!counts && !!(counts.articles || counts.quizes || counts.videos)
  )
})
</script>

<template>
  <div class="relative aspect-[1/1] w-full lg:h-[511px]">
    <div
      class="absolute inset-0 z-10 -translate-x-2 translate-y-2 rounded-2xl bg-[--theme-lightest]"
    ></div>
    <div
      class="absolute inset-0 z-20 shadow-md rounded-xl"
      :class="{
        'hover:-translate-y-1 hover:translate-x-1 duration-200':
          subjectHasContent,
      }"
      :style="
        imageType === 'backgroundImage' ? calculateCardImageStyle : {}
      "
    >
      <router-link
        v-if="chapter.frontendHref"
        :id="`chapter-card-${chapter.id}`"
        data-pendo="chapter-card"
        class="z-30 flex flex-col justify-between w-full h-full px-4 py-6 item-start rounded-xl lg:flex-row lg:items-end lg:px-11 lg:py-20"
        :class="[
          ...cardGradientClass,
          !subjectHasContent
            ? 'pointer-events-none cursor-default'
            : 'cursor-pointer',
        ]"
        :to="chapter.frontendHref"
      >
        <div
          class="flex items-center justify-center h-3/5 lg:h-full lg:w-1/2"
        >
          <img
            v-if="imageType === 'illustration' && chapter.img"
            :src="chapter.img.src"
            :alt="chapter.img.alt"
            class="object-contain w-full h-full"
          />
        </div>

        <UChapterCardContent :chapter="chapter" />
      </router-link>
    </div>
  </div>
</template>
