<script setup lang="ts">
import type { NavProps } from "~/types/nav.d"
import type { Folder } from "~/types/folder"

/**
 * @see https://github.com/vuejs/core/issues/4294#issuecomment-896082769
 * import type { NavProps } from '~/types/nav.d'
 * withDefaults(defineProps<NavProps>(), {})
 */

const props = defineProps({
  folders: {
    type: Array as PropType<Folder[]>,
    default: () => [],
  },
  data: {
    type: Array as PropType<NavProps["data"]>,
    required: true,
    default: () => [],
  },
  listType: {
    type: String as PropType<NavProps["listType"]>,
    default: "ul",
  },
  pageType: {
    type: String as PropType<string>,
    default: "subject",
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const checkSubjectNotInFolders = (parentLocationId: number | null) => {
  return (
    props.folders.filter(i => i.id === parentLocationId).length === 0
  )
}

const subjectsNotInFolders = computed(() => {
  const subjects: NavProps["data"] = []
  props.data.forEach(i => {
    if (checkSubjectNotInFolders(i.parentLocationId)) {
      subjects.push(i)
    }
  })

  return subjects
})
</script>

<template>
  <ul
    class="nav-list no-scrollbar after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:z-20 after:h-[18%] after:w-full after:bg-gradient-to-t after:from-[--theme-darkest] after:to-transparent after:px-2 after:content-['']"
    :class="{
      'list-decimal': listType === 'ol',
    }"
  >
    <div class="pb-32">
      <slot name="list-top" />
      <li
        v-for="(i, key) in folders"
        :id="`nav-element-${i.id}`"
        :key="key"
        class="nav-elements"
      >
        <u-nav-list-folder-item
          :folder="i"
          :page-type="pageType"
          :licence="licence"
          :data="data"
        />
      </li>
      <li
        v-for="(i, key) in subjectsNotInFolders"
        :id="`nav-element-${i.id}`"
        :key="key"
        class="nav-elements"
      >
        <u-nav-list-item
          :item="i"
          :page-type="pageType"
          :licence="licence"
        />
      </li>
    </div>
  </ul>
</template>

<style scoped lang="postcss">
.nav-list {
  @apply list-inside overflow-scroll text-white;
  > .nav-elements {
    @apply hidden md:block;
  }
}
</style>
