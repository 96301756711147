<script setup lang="ts">
import type { Track } from "~/types/audio"

const props = defineProps<{
  currentlyPlaying: number | undefined
  playlist: Track[]
}>()

const personalizationStore = usePersonalizationStore()
const productVariantsStore = useProductVariantsStore()
const audioStore = useAudioStore()

const isArticleRead = (trackId: number) => {
  if (
    personalizationStore.readHistory.find(
      readHistoryTrack => readHistoryTrack.id === trackId,
    )
  ) {
    return true
  }
  return false
}

const handlePlay = (trackId: number) => {
  audioStore.setCurrentTrack(trackId)
  const element = document.getElementById(`${trackId}`)
  if (element) {
    element.scrollIntoView({ behavior: "smooth" })
  }
}

const getStatusIcon = (trackId: number) => {
  return isArticleRead(trackId) ? "circle-filled" : "circle-hollow"
}

onMounted(() => {
  const element = document.getElementById(`${props.currentlyPlaying}`)
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    })
  }
})
</script>
<template>
  <section
    v-if="props.playlist && props.playlist.length > 0"
    class="relative mt-10 flex h-full w-full max-w-[90%] flex-col items-center justify-start overflow-hidden md:max-w-[70%]"
  >
    <ol
      class="h-full w-full overflow-y-auto p-6 pb-10 pt-6 [mask-image:linear-gradient(to_bottom,black_calc(100%-8rem),transparent)] md:mt-20 md:pb-32"
    >
      <li
        v-for="track in props.playlist"
        :id="`${track.id}`"
        :key="track.id"
        class="font-national mb-6 flex w-full !cursor-pointer justify-between gap-2 border-b-[2px] border-solid border-white border-opacity-25 pb-4 text-xl font-normal leading-5 text-lightest hover:brightness-[85%] md:text-2xl"
        :class="{
          '!font-bold !text-lighter':
            track.id === props.currentlyPlaying,
        }"
        role="button"
        @click="handlePlay(track.id)"
      >
        <h3 class="line-clamp-2">
          {{ track.title }}
        </h3>
        <div class="flex items-center gap-4">
          <div
            v-if="track.id === props.currentlyPlaying"
            class="flex items-center justify-center gap-2"
          >
            <p class="hidden md:block">
              Lytter<span class="sr-only"> til denne artikkelen</span
              >...
            </p>
            <UIcon
              name="headphones"
              size="md"
              class="animate-pulse"
            />
          </div>
          <UIcon
            v-else-if="
              productVariantsStore.activeVariant?.userHasLicence
            "
            :name="getStatusIcon(track.id)"
            size="md"
          />
        </div>
      </li>
    </ol>
  </section>
  <h2
    v-if="!props.playlist || props.playlist.length === 0"
    class="font-light text-white"
  >
    Vi fant ingen lydspor dessverre. Ta gjerne kontakt med oss om du
    skulle ønske det fantes, eller om dette virker som en feil!
  </h2>
</template>
