import { useQuery, queryOptions } from "@tanstack/vue-query"
import { getLicences } from "~/api/getLicences"
import { isAuthorized } from "~/utilities/auth"
import type { MaybeRefOrGetter } from "vue"

export const LICENCES_QUERY = queryOptions({
  queryKey: ["licences"],
  queryFn: getLicences,
  enabled: isAuthorized,
})

export function useLicences(
  refetchInterval?: MaybeRefOrGetter<number | false>,
) {
  const { data: licences } = useQuery({
    ...LICENCES_QUERY,
    refetchInterval,
  })

  const activeLicences = computed(
    () =>
      licences.value?.filter(
        licence => new Date(licence.endDate) > new Date(),
      ) ?? [],
  )

  return {
    allLicences: computed(() => licences.value ?? []),
    activeLicences,
  }
}
