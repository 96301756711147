import { acceptHMRUpdate, defineStore } from "pinia"
import { getGroupedProductVariants } from "~/utilities/grouped-product-variants"
import { ProductVariants } from "~/models/Content/ContentProducts"
import { getProductPageList } from "~/api/getProductPageList"
import { getContent } from "~/api/getContent"
import { useQuery } from "@tanstack/vue-query"

const PRODUCTS_LOCATION_ID = import.meta.env.VITE_CMS_PRODUCTS_LOCATION_ID as string | "0"

export const useProductVariantsStore = defineStore("products", () => {
  /** State */
  const licence = useLicences(60_000)

  const { data: productPages } = useQuery({
    queryKey: ["product-pages"],
    staleTime: 1000 * 60 * 60, // 1 hour
    queryFn: getProductPageList,
  })

  const { data: productVariantsRaw } = useQuery({
    queryKey: ["product-variants"],
    staleTime: 1000 * 60 * 60, // 1 hour
    queryFn: () =>
      getContent<ProductVariants>({
        contentTypeCriterion: ["product"],
        parentLocationIdCriterion: [parseInt(PRODUCTS_LOCATION_ID)],
      }),
  })

  const productsMap = computed(() => {
    const map = getGroupedProductVariants(
      productVariantsRaw.value,
      productPages.value,
    )

    for (const key in map) {
      const product = map[key]
      if (product) {
        product.variants.sort((a, b) =>
          a.shortVariantLabel.localeCompare(b.shortVariantLabel),
        )
        /* Add a flag to the products of which the user has a licence for */
        for (const variant of product.variants) {
          variant.licence = licence.activeLicences.value.find(
            licence => licence.ean === variant.ean,
          )
          variant.userHasLicence = !!variant.licence
        }
      }
    }

    return map
  })

  const route = useRoute<"product">()

  /* Set active product and active variant */
  const activeProduct = computed(
    () => productsMap.value[route.params.product],
  )

  watch(activeProduct, value => {
    localStorage.setItem(
      "lastActiveProduct",
      value?.productKey ?? "exphil",
    )
  })

  const activeVariant = computed(() => {
    const variants = activeProduct.value?.variants ?? []
    return variants.length === 1
      ? variants[0]
      : variants.find(v => v.variantKey === route.params.variant)
  })

  /* Create an implicit object in list format for easier use */
  const productsList = computed(() =>
    Object.values(productsMap.value ?? {}).sort((a, b) =>
      a.productTitle.localeCompare(b.productTitle),
    ),
  )

  return {
    activeVariant,
    activeProduct,
    productsList,
    productVariants: computed(() =>
      productsList.value.flatMap(p => p.variants),
    ),
  }
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useProductVariantsStore, import.meta.hot),
  )
