<script setup lang="ts">
import pendo from "~/analytics/pendo"
import { useQueryClient } from "@tanstack/vue-query"
import { LICENCES_QUERY } from "~/composables/useLicences"
import { isAuthorized } from "~/utilities/auth"
import { capitalize } from "~/utilities/string"

setupFeatureFlag()

const { init, user } = useAuth()
const ui = useUiStore()
const route = useRoute<"product">()
const router = useRouter()
const ready = ref(false)

const productContent = useProductContentStore()
const productVariants = useProductVariantsStore()
const applicationMeta = useApplicationMetaStore()

applicationMeta.setNewestVersion()

const queryClient = useQueryClient()

init(router).then(async () => {
  usePwaCache()
  if (isAuthorized.value) {
    await queryClient.prefetchQuery(LICENCES_QUERY)
  }
  ready.value = true
  pendo(route, user.value.uid)
})

// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
const headConfig = {
  title: computed(() => {
    const title =
      route.meta.title ||
      productContent.activeArticle?.title ||
      productContent.activeSubject?.title ||
      "Bestå eksamen lettere"

    const product = route.params.product?.replaceAll("-", " ") ?? ""

    return [
      title,
      " - ",
      import.meta.env.VITE_APP_NAME,
      capitalize(product),
    ]
      .filter(Boolean)
      .join(" ")
  }),
  meta: [
    { name: "description", content: String(route.meta.description) },
  ],
  link: [{ rel: "icon", type: "image/x-icon" }],
  bodyAttrs: {
    "data-theme": computed(
      () => productVariants.activeProduct?.theme ?? "kunne",
    ),
    class: computed(() => {
      if (productContent.activeArticle?.video) {
        return "bg-[--theme-dark]"
      } else if (productContent.activeArticle) {
        return "bg-platform-paper"
      } else if (productVariants.activeVariant) {
        return "bg-[--theme-darkest]"
      } else if (productVariants.activeProduct) {
        return "bg-[--theme-lightest]"
      } else {
        return "bg-platform-paper"
      }
    }),
  },
}

useHead(headConfig)
</script>

<template>
  <!-- Maintenance Banner -->
  <UMaintenanceBanner />

  <!-- Orchestrating async dependencies in a component tree -->
  <!-- Wrap non SSR friendly components for now -->
  <suspense>
    <div id="root" class="min-h-screen">
      <template v-if="ready">
        <UDeviceLimiter />
        <u-toast />
        <router-view />
        <u-pay-result />
        <UAudioPlayer />
        <u-download-media-background />
      </template>
      <u-load-screen v-else :data="ui.loader" />
      <VueQueryDevtools />
    </div>
  </suspense>
</template>

<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
