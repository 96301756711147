import {
  object,
  string,
  array,
  nullable,
  optional,
  number,
  InferOutput,
  intersect,
} from "valibot"
import {
  BaseItemSchema,
  HtmlContentSchema,
  TimestampSchema,
} from "./common.schema"

export const MediaMetadataSchema = object({
  cmid: nullable(string()),
  contentItemId: string(),
  docType: string(),
  duration: nullable(string()),
  elementURI: string(),
  embedCode: nullable(string()),
  embedURL: string(),
  ezObjectId: nullable(string()),
  image: nullable(string()),
  materialID: nullable(string()),
  mimeType: string(),
  pageID: nullable(string()),
  rdfResource: string(),
  returnPath: string(),
})

export const CaptionSchema = object({
  captionFile: object({
    uri: string(),
    fileName: string(),
  }),
  language: string(),
})

export const VideoSchema = object({
  embedCode: string(),
  id: string(),
  title: string(),
  previewTitle: string(),
  publishedBy: string(),
  published: string(),
  _type: object({
    identifier: string(),
  }),
  img: nullable(
    object({
      alt: string(),
      src: string(),
    }),
  ),
  timestamps: array(TimestampSchema),
  transcription: HtmlContentSchema,
  metadata: MediaMetadataSchema,
  captions: array(
    object({
      captionFile: object({
        uri: string(),
        fileName: string(),
      }),
      language: string(),
    }),
  ),
})

export const AudioSchema = object({
  title: string(),
  shortTitle: string(),
  caption: string(),
  timestamps: array(TimestampSchema),
  id: string(),
  metadata: MediaMetadataSchema,
})

export const QuizSchema = object({
  id: string(),
  title: string(),
})

export const FileSchema = intersect([
  BaseItemSchema,
  object({
    name: string(),
    description: optional(string()),
    binaryFile: object({
      fileName: string(),
      fileSize: number(),
      mimeType: string(),
      id: string(),
      uri: string(),
      url: string(),
      downloadCount: number(),
    }),
  }),
])

export type ContentFile = InferOutput<typeof FileSchema>
