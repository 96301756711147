<script setup lang="ts">
const router = useRouter()
</script>

<template>
  <div
    class="mt-5 flex items-center justify-center rounded-2xl bg-white bg-opacity-10 py-40"
  >
    <div class="text-white">
      <p class="flex justify-center font-numeraWebRegular">
        Du er offline
      </p>
      <p class="font-national2">
        Denne funksjonen fungerer ikke offline.
      </p>
      <div class="flex justify-center">
        <u-button
          variant="filled"
          class="mt-5"
          size="sm"
          @click="router.back()"
        >
          <div class="flex items-center justify-center">
            <u-icon name="arrow-left" size="sm" class="mr-2" />
            <span class="text-lg">Gå tilbake</span>
          </div>
        </u-button>
      </div>
    </div>
  </div>
</template>
