<script setup lang="ts">
import { isAuthorized, logout } from "~/utilities/auth"
import { ProductsList } from "~/models/Content/ContentProducts"

const props = defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: false,
    default: undefined,
  },
  dark: {
    type: Boolean,
    default: true,
  },
  backButton: {
    type: Boolean,
    default: false,
  },
})

const variantStore = useProductVariantsStore()

const route = useRoute<"product">()
const title = computed(
  () =>
    props.product?.productTitleHtml ||
    props.product?.productTitle ||
    variantStore.activeProduct?.productTitle ||
    "",
)

const hasAtLeastOneReleasedProduct = computed(
  () => variantStore.activeProduct?.hasAtLeastOneReleasedProduct,
)

const loginPageUrl = useLoginPageUrl()

const showLoginButton = computed(() => {
  return (
    loginPageUrl.value &&
    !route.path.includes("betaling") &&
    !route.path.includes("logg-inn")
  )
})

const isSmallScreen = useMediaQuery("(max-width: 744px)")
</script>

<template>
  <header
    class="flex items-center justify-center w-full h-full overflow-hidden"
  >
    <div
      class="flex h-full w-full max-w-[100em] items-center justify-between pt-6 pb-4 lg:py-10"
    >
      <div class="flex gap-4">
        <UButtonNew v-if="backButton" variant="quite" to="/"
          ><UIcon
            name="arrow-left"
            size="md"
            class="pl-4 hover:text-[--u-contrast]"
        /></UButtonNew>
        <ULogo :product="product" :dark="dark" />
      </div>
      <UButtonNew
        v-if="
          title && hasAtLeastOneReleasedProduct && showLoginButton
        "
        :to="!isAuthorized ? loginPageUrl : undefined"
        :size="isSmallScreen ? 'sm' : 'lg'"
        variant="quite"
        @click="
          () => (isAuthorized ? logout(route.params.product) : null)
        "
      >
        {{ !isAuthorized ? "Logg inn" : "Logg ut" }}
      </UButtonNew>
      <UButtonNew
        v-else-if="!showLoginButton && isAuthorized"
        :size="isSmallScreen ? 'sm' : 'lg'"
        variant="quite"
        @click="logout(route.params.product)"
      >
        Logg ut
      </UButtonNew>
    </div>
  </header>
</template>
