import type { Criterion, Query } from "~/models/Search"

export function buildQuery(
  query: Query,
  criterion: Criterion,
  limit: number,
  offset: number,
  role: string = "anon",
) {
  let queryString = `fullText=${query.text || "*"}`
  if (query.fuzziness) {
    queryString += `&fuzziness=${query.fuzziness}`
  }
  if (query.spellcheck) {
    queryString += "&spellcheck=1"
  }
  if (criterion.contentTypeCriterion?.length) {
    queryString += `&${criterion.contentTypeCriterion.map(value => `contentType[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.subtreeCriterion?.length) {
    queryString += `&${criterion.subtreeCriterion.map(value => `subtrees[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.labelFieldCriterion?.length) {
    queryString += `&${criterion.labelFieldCriterion.map(value => `label[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.activityFieldCriterion?.length) {
    queryString += `&${criterion.activityFieldCriterion.map(value => `activity[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.gradeFieldCriterion?.length) {
    queryString += `&${criterion.gradeFieldCriterion.map(value => `grade[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.contentIdCriterion?.length) {
    queryString += `&${criterion.contentIdCriterion.map(value => `contentId[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.parentLocationIdCriterion?.length) {
    queryString += `&${criterion.parentLocationIdCriterion.map(value => `parentLocationId[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.locationIdCriterion?.length) {
    queryString += `&${criterion.locationIdCriterion.map(value => `locationId[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion.accessStateCriterion?.length) {
    queryString += `&${criterion.accessStateCriterion.map(value => `access[]=${encodeURIComponent(value)}`).join("&")}`
  }
  if (criterion?.sortField) {
    queryString += `&sortField=${criterion.sortField.toLowerCase()}`
  }
  if (criterion?.sortOrder) {
    queryString += `&sortOrder=${{ asc: "ascending", desc: "descending" }[criterion.sortOrder]}`
  }
  if (query.boost) {
    queryString += `&${createKeyValueParams(query.boost, "boost")}`
  }
  return `${queryString}&mainLocation=${criterion.mainLocationCriterion ? 1 : 0}&limit=${limit}&offset=${offset}&role=${role}`
}

function createKeyValueParams(
  object: Record<string, string>,
  field: string,
): string {
  const fieldsParam: string[] = []
  Object.keys(object).forEach(key => {
    fieldsParam.push(
      `${field}[${key}]=${encodeURIComponent(object[key])}`,
    )
  })
  return fieldsParam.join("&")
}
