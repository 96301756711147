<script setup lang="ts">
const { data } = useMarketing()

const sidePadding = "lg:px-32 px-4"

const article = computed(() => data.value?.marketingArticles?.[0])
</script>

<template>
  <div
    class="flex min-h-[70dvh] w-full items-start justify-center bg-platform-paper text-dark-blue-darker lg:py-20"
  >
    <article v-if="article" class="w-[90vw] max-w-[65rem]">
      <h2
        class="mb-10 font-national2condensed text-5xl font-medium leading-10 lg:text-7xl lg:leading-[80px]"
        :class="sidePadding"
      >
        {{ article?.title }}
      </h2>
      <h3
        v-if="article?.subtitle"
        :class="sidePadding"
        class="text-2xl font-medium leading-9 font-national2condensed lg:text-3xl lg:leading-10"
        v-html="article?.subtitle.html5"
      />
      <div
        v-for="section in article.sections"
        :key="section?.sectionTitle"
        class="flex flex-col gap-4 mt-4"
      >
        <h4
          v-if="
            !section?.sectionBodyIsAQuote && section?.sectionTitle
          "
          class="mt-10 font-national2condensed text-4xl font-medium leading-10 lg:text-[56px] lg:leading-[60px]"
          :class="sidePadding"
        >
          {{ section?.sectionTitle }}
        </h4>
        <span
          v-if="
            section?.sectionBody.html5 && section?.sectionBodyIsAQuote
          "
          class="mb-[-60px] h-fit text-center font-national2 text-7xl italic text-red-400 lg:text-[100px]"
          >“</span
        >

        <blockquote
          v-if="
            section?.sectionBody.html5 && section?.sectionBodyIsAQuote
          "
          class="mb-16 px-0 text-center font-national2condensed text-3xl italic leading-10 text-red-400 lg:text-5xl lg:leading-[58px]"
          v-html="section?.sectionBody.html5"
        />
        <p
          v-else-if="section?.sectionBody.html5"
          class="text-lg font-normal leading-normal tracking-wide font-numeraWebRegular lg:text-xl lg:leading-8"
          :class="{
            [sidePadding]: !section?.sectionBodyIsAQuote,
          }"
          v-html="section?.sectionBody.html5"
        />

        <img
          v-if="section?.image"
          :src="section?.image.src"
          :alt="section?.image.alt"
          class="my-10 max-h-[50dvh] w-full rounded-lg object-cover"
        />
        <div
          v-if="section?.buttonText && section?.buttonUrl"
          class="flex flex-col items-start justify-start gap-4 py-4 md:flex-row"
          :class="sidePadding"
        >
          <UButtonNew
            v-if="section?.buttonText.toLowerCase().includes('kjøp')"
            theme="dark"
            :to="section?.buttonUrl.split('/').slice(0, -1).join('/')"
          >
            Les mer
          </UButtonNew>
          <UButtonNew
            theme="cta"
            pill
            variant="filled"
            :to="
              section?.buttonUrl.includes('https://')
                ? undefined
                : section?.buttonUrl
            "
            :href="
              section?.buttonUrl.includes('https://')
                ? section?.buttonUrl
                : undefined
            "
            :target="
              section?.buttonUrl.includes('https://')
                ? '_blank'
                : undefined
            "
            ><template
              v-if="section?.buttonUrl.includes('https://')"
              #append
            >
              <UIcon name="link-external" />
            </template>
            {{ section?.buttonText }}
          </UButtonNew>
        </div>
      </div>
    </article>
  </div>
</template>
