import { sendMessageToSW } from "~/utilities/pwa"

export function usePwaCache() {
  const user = useAuth()
  const products = useProductVariantsStore()

  const cacheName = computed<PWA.CacheStrategy>(() => {
    const authState = user.isAuthorized.value
      ? "LOGGED_IN"
      : "LOGGED_OUT"
    const activeLicence = products.activeVariant?.licence
      ? `LICENCE_${products.activeVariant.licence.ean}`
      : "NO_ACTIVE_LICENCE"
    return `${authState}_${activeLicence}`
  })

  watch(
    cacheName,
    strategy => {
      sendMessageToSW({
        type: "CHANCE_ACTIVE_CACHE",
        strategy,
      })
    },
    { immediate: true },
  )
}
