<script lang="ts" setup>
import UButtonNew from "../Interactions/UButtonNew.vue"
import { computed } from "vue"
import { useRoute } from "vue-router"

const route = useRoute<"product">()

const productParam = computed(() => route.params.product)
const variantParam = computed(() => route.params.variant)

// Track the active tab based on the URL
const activeTabComputed = computed(() => {
  const path = route.path
  if (path.includes("min-side")) {
    return "profile"
  } else if (path.includes("sok")) {
    return "search"
  } else {
    return "home"
  }
})
</script>

<template>
  <nav>
    <ol
      class="flex items-end justify-center w-screen px-1 pt-1.5 pb-4 bg-[--theme-darkest] md:hidden gap-4"
    >
      <li>
        <UButtonNew
          theme="light"
          variant="outline"
          stacked
          size="sm"
          :class="
            activeTabComputed === 'home'
              ? '!text-white'
              : 'brightness-[85%]'
          "
          class="!w-[85px] hover:!text-white"
          :to="`/produkter/${productParam}/${variantParam}`"
          :aria-current="
            activeTabComputed === 'home' ? 'page' : undefined
          "
        >
          <template #prepend>
            <u-icon name="k" class="mb-2" size="lg" />
          </template>
          Hjem
        </UButtonNew>
      </li>
      <li>
        <UButtonNew
          theme="light"
          variant="outline"
          class="!w-[85px] hover:!text-white"
          :class="
            activeTabComputed === 'search'
              ? '!text-white'
              : 'brightness-[85%]'
          "
          stacked
          size="sm"
          :to="
            `/produkter/${[route.params.product, route.params.variant]
              .filter(Boolean)
              .join('/')}` + '/sok'
          "
          :aria-current="
            activeTabComputed === 'search' ? 'page' : undefined
          "
        >
          <template #prepend>
            <u-icon name="search" class="mb-2" size="lg" />
          </template>
          Søk
        </UButtonNew>
      </li>
      <li>
        <UMyProfileButton
          mobile
          class="!w-[85px] hover:!text-white"
          :class="
            activeTabComputed === 'profile'
              ? '!text-white'
              : 'brightness-[85%]'
          "
          :path="[route.params.product, route.params.variant]"
          :aria-current="
            activeTabComputed === 'profile' ? 'page' : undefined
          "
        />
      </li>
    </ol>
  </nav>
</template>
