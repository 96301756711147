/**
 * Waits for a DOM node to be mounted
 * Default selector type: `getElementById`
 * Usage: `const el = await onMountedDOMNode('id-of-element')`
 * @param selector
 * @returns HTMLElement
 */
export function onMountedDOMNode(
  selector: string,
  selectorType?: "querySelector" | "querySelectorAll",
): Promise<Element | null> {
  const getEl = () => {
    if (selectorType === "querySelector") {
      return document.querySelector(selector) as unknown as
        | Element
        | PromiseLike<Element | null>
        | null
    } else if (selectorType === "querySelectorAll") {
      return document.querySelectorAll(selector) as unknown as
        | Element
        | PromiseLike<Element | null>
        | null
    } else {
      return document.getElementById(selector) as
        | Element
        | PromiseLike<Element | null>
        | null
    }
  }

  return new Promise(resolve => {
    if (getEl()) {
      return resolve(getEl())
    }

    const observer = new MutationObserver(() => {
      if (getEl()) {
        resolve(getEl())
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}

/**
 * Calculate the percentage of time
 * @param {Number} timeElapsed
 * @param {Number} totalTime
 * @returns a numeric value
 */
export function caculateTimePercentage(
  timeElapsed: number,
  totalTime: number,
) {
  return Number(((100 * timeElapsed) / totalTime).toFixed(2))
}

export function observeHTMLElement(
  element?: HTMLElement | null,
  callback?: (arg0: MutationRecord, arg1: MutationObserver) => void,
) {
  const el = document.querySelector("html") as HTMLElement
  const htmlElement = element || el

  const mut = new MutationObserver(([mutation], mut) => {
    if (callback) callback(mutation, mut)
  })

  mut.observe(htmlElement, {
    attributes: true,
  })
}
