<script setup lang="ts">
import { useProductContentStore } from "~/store/productContentStore"
import { useMediaCounts } from "~/composables/useMediaCounts"
import { unref } from "vue"
import { storeToRefs } from "pinia"
import { IconName } from "~/models/Content/ContentProductPageMediaTypes"

interface MediaTypes {
  icon: IconName
  count: number
  text: string
}

const { subjects } = storeToRefs(useProductContentStore())
const { activeVariant } = storeToRefs(useProductVariantsStore())

const mediaCounts = subjects.value.map(subject =>
  useMediaCounts(subject),
)

// Summarize media counts
const summarizedCounts = computed(() => {
  return mediaCounts.reduce(
    (acc, media) => {
      const data = unref(media.data)
      if (data) {
        acc.videos += data.videos
        acc.podcasts += data.podcasts
        acc.quizes += data.quizes
      }
      return acc
    },
    { articles: 0, videos: 0, podcasts: 0, quizes: 0 },
  )
})

const mediaTypes = computed<MediaTypes[]>(() => [
  {
    icon: "article",
    count: subjects.value.length,
    text: subjects.value.length === 1 ? "kapittel" : "kapitler",
  },
  {
    icon: "medal",
    count: summarizedCounts.value.quizes,
    text: summarizedCounts.value.quizes === 1 ? "quiz" : "quizzer",
  },
  {
    icon: "video",
    count: summarizedCounts.value.videos,
    text: summarizedCounts.value.videos === 1 ? "video" : "videoer",
  },
  {
    icon: "headphones",
    count: summarizedCounts.value.podcasts,
    text:
      summarizedCounts.value.podcasts === 1 ? "podcast" : "podcaster",
  },
])
</script>

<template>
  <div
    class="p-6 md:p-8 w-full md:w-auto bg-[--theme-lightest] rounded-lg gap-6 md:gap-8 font-national2condensed text-paywall-mobile-stats-md md:text-paywall-desktop-stats-md text-[--theme-dark]"
  >
    <!-- Paid -->
    <ul
      v-if="!activeVariant?.isFree"
      class="translate-x-1/3 md:translate-x-0 md:flex-row flex flex-col gap-4 md:gap-6"
    >
      <li
        v-for="type in mediaTypes"
        :key="type.icon"
        :class="[{ '!hidden': !type.count }]"
        class="flex items-center gap-2"
      >
        <u-icon
          :name="type.icon"
          size="sm"
          class="p-2 bg-[--theme-dark] rounded-full text-white"
        />
        <span>{{ type.count }}&nbsp;{{ type.text }}</span>
      </li>
    </ul>
    <!-- Free -->
    <ul v-else class="space-y-2">
      <li class="flex items-center gap-2 md:gap-4">
        <u-icon
          name="check-filled"
          class="text-[--theme-primary]"
          size="auto"
        />
        <span>Eksklusive oppdateringer</span>
      </li>
      <li class="flex items-center gap-2 md:gap-4">
        <u-icon name="check-filled" size="auto" />
        <span>Info når produktet kommer for salg</span>
      </li>
    </ul>
  </div>
</template>
