<script setup lang="ts">
import { Article } from "~/types/article"

const props = defineProps({
  length: {
    type: String,
    default: "Laster...",
  },
  title: {
    type: String,
    default: "Laster...",
  },
  img: {
    type: Object as PropType<Article["img"]>,
    default: null,
  },
})

const convertToDigits = (x: number) => (x < 10 ? `0${x}` : x)

const getMinutes = (videoLengthRaw: string) => {
  const sec = parseInt(videoLengthRaw, 10)
  const minutes = Math.floor(sec / 60)
  const seconds = sec - minutes * 60

  return `${convertToDigits(minutes)}:${convertToDigits(seconds)}`
}

const videoLength = computed(() => {
  return getMinutes(props.length)
})
</script>
<template>
  <div
    class="flex h-full w-full flex-col overflow-clip rounded-2xl bg-darker text-white"
  >
    <h3
      class="p-4 font-national2condensed text-2xl lg:text-4xl font-medium lg:p-8 xl:text-5xl"
    >
      {{ title }}
    </h3>

    <div
      class="flex h-full w-full flex-col"
      :class="{ 'bg-black': !img?.src }"
    >
      <div class="flex-grow-1 relative h-full w-full">
        <img
          v-if="img?.src"
          :src="img.src"
          :alt="img.alt"
          class="h-full w-full object-cover"
        />

        <div
          class="absolute left-8 top-8 flex items-center justify-center gap-2 rounded-lg bg-[--theme-darker] p-2 px-4 pr-5 font-national2 text-xl font-bold lg:text-2xl"
        >
          <UIcon name="play-filled" />
          <span v-if="videoLength && !videoLength.includes('NaN')">{{
            videoLength
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
