<script setup lang="ts">
const route = useRoute()
const { data } = useMarketing()

const page = computed(() =>
  data.value?.pages?.find(p => p.id === Number(route.query.side)),
)
</script>

<template>
  <div
    class="font- grid grid-cols-[minmax(0,80ch)] place-content-center px-10 py-20 pb-52 text-dark-blue-darker"
  >
    <article v-if="page" class="richtext font-numeraWebRegular">
      <section
        v-for="section in page.sections"
        :key="section.id"
        v-html="section.richtext"
      />
    </article>
  </div>
</template>

<route lang="yaml">
path: "/om/:path?"
meta:
  title: Om
  layout: marketing
</route>
