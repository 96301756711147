/**
 * Detect contrast of color
 * Accepts a hex code
 * @example isColorDark('#color') ? '#isDark' : '#isLight'
 */
export function isColorDark(color: string) {
  let r = 0
  let g = 0
  let b = 0

  // Matches rgb(r,g,b) and rgba(r,g,b,a)
  const match = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
  if (match) {
    r = Number(match[1])
    g = Number(match[2])
    b = Number(match[3])

    const rawAlpha = match[4]
    if (rawAlpha) {
      const alpha = Number(rawAlpha)

      // Blend alpha into the color components, using white rgb(255,255,255) as background
      r = (1 - alpha) * 255 + alpha * r
      g = (1 - alpha) * 255 + alpha * g
      b = (1 - alpha) * 255 + alpha * b
    }
  } else {
    const value = hexToRgb(color)
    if (value) {
      r = value.r
      g = value.g
      b = value.b
    }
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5 ? "light" : "dark"
}

/**
 * Converts Hex to RGB
 * @example hexToRgb("#0a3678") // "10,54,120"
 * @param hex
 * @returns
 */
export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (result) {
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return { r, g, b }
  }
  return null
}
