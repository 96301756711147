<script setup lang="ts">
import {
  formProductKeys,
  productForms,
} from "~/components/Hubspot/productForms"
import UButtonNew from "~/components/Interactions/UButtonNew.vue"
const products = useProductVariantsStore()

const activeProductKey = computed(() =>
  formProductKeys.find(
    it => it === products.activeProduct?.productKey,
  ),
)
</script>

<template>
  <UHubspotForm
    v-if="activeProductKey"
    :form-url="productForms[activeProductKey].url"
    :sub-title="productForms[activeProductKey].subTitle"
    title="Hold deg oppdatert på lanseringen!"
  />
  <section v-else class="flex flex-col gap-10 mt-20 sm:items-center">
    <p class="font-national2">
      Vi har dessverre ikke lagt ut interesseskjemaet for dette
      produktet, prøv igjen senere!
    </p>
    <UButtonNew
      variant="filled"
      theme="cta"
      pill
      :href="`/produkter/${products.activeProduct?.urlFormatProductTitle}`"
    >
      Gå til produktet
    </UButtonNew>
  </section>
</template>

<route lang="yaml">
name: registerInterest
meta:
title: Registrer interesse
</route>
