type QueryParams = Record<string, string | undefined>

export const checkoutPageUrl = (
  productParam: string,
  queryParams?: QueryParams,
) => {
  let query = ""

  if (queryParams) {
    const filteredQueryParams = filterQueryParams(queryParams)
      .toString()
      .replace(/%2F/g, "/")

    query = filteredQueryParams ? `?${filteredQueryParams}` : ""
  }

  return `/produkter/${productParam}/betaling${query}`
}

export const loginPageUrl = (productParam: string) =>
  `/produkter/${productParam}/logg-inn`

function filterQueryParams(
  queryParams?: QueryParams,
): URLSearchParams {
  if (!queryParams) {
    return new URLSearchParams()
  } else {
    const queryParamsToRemove: string[] = []

    for (const param in queryParams) {
      if (
        queryParams[param] === undefined ||
        queryParams[param] === null
      ) {
        queryParamsToRemove.push(param)
      }
    }

    queryParamsToRemove.forEach(param => delete queryParams[param])

    return new URLSearchParams(queryParams as Record<string, string>)
  }
}

export function interpolatePath(
  path: string,
  params: Record<string, string>,
) {
  return path.replace(/:(\w+)/g, (_, key) => {
    if (params[key] !== undefined) {
      return encodeURIComponent(params[key])
    }
    throw new Error(`Missing param for key: ${key}`)
  })
}

export function sanitizeForUrl(text: string | undefined) {
  if (text === undefined) return undefined

  const formatted = formatForURL(text)
  return formatted.toLowerCase().replace(/[^\w-]/g, "")
}

export const formatForURL = (url: string) => {
  return decodeURIComponent(url.toLowerCase())
    .replaceAll(/æ/gi, "ae")
    .replaceAll(/å/gi, "aa")
    .replaceAll(/ø/gi, "oe")
    .replaceAll(/\s/g, "-")
    .replaceAll(/[,;:]/g, "")
}
