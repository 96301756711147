import axios, { InternalAxiosRequestConfig } from "axios"
import { merge } from "lodash-es"
import { client } from "~/utilities/auth"
import type { AxiosError } from "axios"

let token: Promise<string | null> | string | null = null

async function getNewToken() {
  if (token?.constructor === Promise) {
    return token
  }

  console.info("[auth] Fetching new token...")
  try {
    await client.refreshAccessToken()
    token = client.getAccessToken()
    console.info("[auth] Got new token")
    return token
  } catch (error) {
    token = null
    console.error("[auth] Failed to get new token:", error)
    throw error // Rethrow the error to allow the caller to handle it.
  }
}

function createRequestAuthInterceptor(ean = false) {
  return (config: InternalAxiosRequestConfig) => {
    if (client.getAccessToken()) {
      const store = useProductVariantsStore()
      // Todo: Call await getNewToken() if access token is about to expire
      return merge(config, {
        headers: {
          Authorization: `Bearer ${client.getAccessToken()}`,
          ...(ean ? { Ean: store.activeVariant?.ean } : {}),
        },
      })
    }

    return config
  }
}

function createResponseErrorInterceptor() {
  return async (error: AxiosError) => {
    switch (error.response?.status) {
      case 401: {
        // Outdated token, use newly fetched one
        if (
          typeof token === "string" &&
          `Bearer ${token}` !== error.config?.headers?.Authorization
        ) {
          return axios(
            merge(error.config, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          )
        }

        // Fetch new token, or await fetch in progress
        return axios(
          merge(error.config, {
            headers: {
              Authorization: `Bearer ${await getNewToken()}`,
            },
          }),
        )
      }

      default: {
        return error
      }
    }
  }
}

export const api = {
  am: axios.create({
    params: { clientId: "kunne_client" },
    baseURL: import.meta.env.VITE_AM_CORE,
  }),
  core: axios.create({
    params: { clientId: "kunne_client" },
    baseURL: import.meta.env.VITE_API_CORE,
  }),
  ecommerce: axios.create({
    baseURL: import.meta.env.VITE_ECOMMERCE_CORE,
  }),
  external: axios.create({
    baseURL: import.meta.env.VITE_EXTERNAL_CORE,
  }),
  ibexaApi: axios.create({
    baseURL: import.meta.env.VITE_IBEXA_API_URL,
    headers: {
      Accept: "application/vnd.ibexa.api.View+json; version=1.1",
      "Content-Type":
        "application/vnd.ibexa.api.ViewInput+json; version=1.1",
    },
  }),
}

const requestAuthInterceptor = createRequestAuthInterceptor()
const requestEanAuthInterceptor = createRequestAuthInterceptor(true)
const responseErrorInterceptor = createResponseErrorInterceptor()

api.am.interceptors.request.use(requestAuthInterceptor)
api.am.interceptors.response.use(undefined, responseErrorInterceptor)
api.external.interceptors.request.use(requestAuthInterceptor)
api.external.interceptors.response.use(
  undefined,
  responseErrorInterceptor,
)
api.ecommerce.interceptors.request.use(requestAuthInterceptor)
api.ecommerce.interceptors.response.use(
  undefined,
  responseErrorInterceptor,
)
api.ibexaApi.interceptors.request.use(requestEanAuthInterceptor)
api.ibexaApi.interceptors.response.use(
  undefined,
  responseErrorInterceptor,
)
