import { ContentType } from "~/models/Content/ContentType"
import { Article } from "~/types/article"
import { Subject } from "~/types/subject"

export const articleTemplates = new Map([
  ["text", { ibexaId: 0, name: "Tekst" }],
  ["quiz", { ibexaId: 3, name: "Oppgaver" }],
  ["video", { ibexaId: 1, name: "Video" }],
])

/**
 * Format articles from raw data (cms).
 * Used for restricted articles.
 * @param restrictedArticlesRaw
 * @param subjectLocationId
 */

export function formatRestrictedArticles(
  restrictedArticlesRaw: Article[],
  subjectLocationId: number,
) {
  return restrictedArticlesRaw.map<Article>((e: Article) => ({
    audio: undefined,
    bg: "platform-paper",
    body: {
      html: `<h1>${e.title}</h1><div class="article-text-placeholder"></div>`,
    },
    color: "black",
    id: e.locationId,
    imageCaption: { html: "" },
    img: e.img,
    intro: { html: "\n" },
    parentLocationId: subjectLocationId,
    priority: e.priority,
    shortTitle: "",
    template: e.template || "UImageWithTextArticle",
    title: e.title,
    type: "article",
    restricted: true,
    childCount: e.childCount,
    // Base Article fields
    locationId: e.locationId,
    contentId: e.contentId,
    pathString: "",
    contentTypeIdentifier: ContentType.Article,
    sortField: "PRIORITY",
    sortOrder: "ASC",
    publishedDate: "",
    label: "",
    thumbnail: "",
    thumbnailAlt: "",
    remoteId: "",
    mainLocationId: 0,
    relationsIds: [],
    children: [],
    relatedItems: [],
  }))
}

export const formatSubjectPage = (
  subject: Subject,
): Article | undefined => {
  if (!subject) return
  const {
    img,
    id,
    parentLocationId,
    title,
    shortTitle,
    subjectPageTitlePosition,
    colorTag,
    priority,
    childCount,
    // introPages
  } = subject
  if (subject.introPages && subject.introPages.length > 0) {
    return {
      id: (id as number) + (parentLocationId || 0),
      parentLocationId: id as number,
      template: "USlidesArticle",
      bg: "platform-paper",
      img: img
        ? {
            id: null,
            ...img,
          }
        : undefined,
      title,
      shortTitle,
      priority,
      subjectPageTitlePosition,
      // introPages,
      colorTag,
      childCount,

      // Base Article fields
      locationId: id as number,
      contentId: id as number,
      pathString: "",
      contentTypeIdentifier: ContentType.Article,
      sortField: "PRIORITY",
      sortOrder: "ASC",
      publishedDate: "",
      label: "",
      thumbnail: "",
      thumbnailAlt: "",
      remoteId: "",
      mainLocationId: 0,
      relationsIds: [],
      children: [],
      relatedItems: [],
    }
  } else return
}
