<script setup>
useHead({
  bodyAttrs: {
    class: "bg-[--theme-lightest]",
  },
})
</script>

<template>
  <div
    class="flex flex-col items-center justify-center w-full mb-14 gap-14"
  >
    <UPlatformDescription />
    <UProductList />
  </div>
</template>

<route lang="yaml">
name: hjem
layout: default
</route>
