<script setup lang="ts">
interface Props {
  label?: string
  modelValue: any
  disabled?: boolean
  diameter?: number
  dark?: boolean
  iconClass?: string
  size: "sm" | "lg"
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  diameter: 24,
})

const emit = defineEmits(["update:modelValue", "click"])

const model = computed({
  get: () => props.modelValue,
  set: e => emit("update:modelValue", e),
})

function toggleCheckbox(event: Event) {
  if (!props.disabled) {
    const newValue = !model.value
    model.value = newValue
    emit("click", newValue)
  }
}

const hover = ref(false)
const mouseDown = ref(false)
</script>

<template>
  <div class="flex cursor-pointer" @click="toggleCheckbox">
    <div>
      <template v-if="model">
        <u-icon
          name="check"
          size="xs"
          class="rounded-full bg-green-60 p-3"
          :class="{
            '!p-2': size === 'sm',
            '!p-3': size === 'lg',
          }"
        />
      </template>
      <template v-else>
        <div
          class="flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
          :class="{
            '!h-[32px] !w-[32px]': size === 'sm',
            'border-[3px]': mouseDown,
            'border-2': !mouseDown,
            'opacity-100': hover,
            'opacity-70': !hover,
          }"
          @mouseenter="
            () => {
              mouseDown = false
              hover = true
            }
          "
          @mouseleave="
            () => {
              mouseDown = false
              hover = false
            }
          "
          @mousedown="
            () => {
              mouseDown = true
              hover = true
            }
          "
          @mouseup="
            () => {
              mouseDown = false
              hover = false
            }
          "
        >
          <u-icon
            name="check"
            :class="{ hidden: !hover }"
            size="xxs"
            :icon-class="iconClass ? iconClass : undefined"
          />
        </div>
      </template>
    </div>
    <label v-if="label" class="ml-3">{{ label }}</label>
  </div>
</template>
