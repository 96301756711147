<script setup lang="ts">
import { toDisplayLanguage } from "../../utilities/videoUtils"
import UButtonNew from "~/components/Interactions/UButtonNew.vue"
import { Video } from "~/types/media"

const {
  video,
  title,
  isDark = true,
  autoPlay = false,
} = defineProps<{
  video: Video
  title?: string
  isDark?: boolean
  autoPlay?: boolean
}>()

const videoElement = ref<HTMLVideoElement | null>(null)
const isPlaying = ref(false)
const videoLoaded = ref(false)
const showTime = ref(true)
const showTranscription = ref(false)

const PROGRESS_MILESTONES = [25, 50, 75, 95]
const reachedMilestones = ref(new Set<number>())

const win = window as any
const pendoAnalytics = win.pendo

const trackingInfo = computed(() => ({
  title: title ?? video.title ?? "-",
  videoId: video.id,
}))

const onLoad = () => {
  videoLoaded.value = true
}

const convertToDigits = (x: number) => (x < 10 ? `0${x}` : x)

const getMinutes = () => {
  if (video.pathString === null || video.pathString === undefined)
    return
  const sec = parseInt(video.metadata?.duration, 10)
  const minutes = Math.floor(sec / 60)
  const seconds = sec - minutes * 60

  return `${convertToDigits(minutes)}:${convertToDigits(seconds)}`
}

const toggleVideoPlay = () => {
  if (
    videoElement.value &&
    (!isPlaying.value || videoElement.value.paused)
  ) {
    if (showTime.value) {
      showTime.value = false
    }
    videoElement.value.play()
    isPlaying.value = true
  } else {
    videoElement.value?.pause()
    isPlaying.value = false
  }
}

const convertToSeconds = (time: string) => {
  return time
    .split(":")
    .reverse()
    .reduce(
      (prev, curr, i) =>
        prev + (curr as unknown as number) * (60 ** i),
      0,
    )
}

const handlePlayClick = (time: string) => {
  const seconds = convertToSeconds(time)

  if (!videoElement.value) return

  videoElement.value.currentTime = seconds
  isPlaying.value = true
  showTime.value = false
  videoElement.value.play()
}

const closeTranscription = () => {
  showTranscription.value = false
}

function checkProgress() {
  if (!videoElement.value || videoElement.value.duration === 0) return

  const progress = (
    (videoElement.value.currentTime / videoElement.value.duration) *
    100
  ).toFixed(0)

  const highestMilestone = PROGRESS_MILESTONES.sort(
    (a, b) => b - a,
  ).find(milestone => Number(progress) >= milestone)

  if (
    highestMilestone &&
    !reachedMilestones.value.has(highestMilestone)
  ) {
    reachedMilestones.value.add(highestMilestone)

    pendoAnalytics.track("Video progress", {
      progress: highestMilestone,
      ...trackingInfo.value,
    })
  }
}

function onPlay() {
  isPlaying.value = true

  if (videoElement.value?.currentTime === 0) {
    pendoAnalytics.track("Video start", {
      ...trackingInfo.value,
    })
  }
}

function onPause() {
  isPlaying.value = false

  if (videoElement.value?.currentTime) {
    pendoAnalytics.track("Video pause", {
      ...trackingInfo.value,
      currentTime: videoElement.value.currentTime,
    })
  }
}

const playbackRate = ref(1)

function onRateChange() {
  const newRate = videoElement.value?.playbackRate
  if (newRate) {
    playbackRate.value = newRate
  }
}

watch(playbackRate, (newValue, previousValue) => {
  pendoAnalytics.track("Speed change", {
    speed: newValue,
    previousSpeed: previousValue,
    ...trackingInfo.value,
  })
})
</script>

<template>
  <section class="@container">
    <div
      class="flex flex-col self-center w-full gap-4 mt-10 mb-10 @lg:flex-row"
    >
      <div
        class="w-full aspect-w-16 aspect-h-9"
        :class="{
          '@xl:w-2/3': showTranscription,
          '@xl:w-full': !showTranscription,
        }"
      >
        <div class="relative w-full h-full">
          <UButtonNew
            v-if="!isPlaying && videoLoaded"
            circle
            theme="cta"
            variant="filled"
            class="absolute inset-0 z-50 m-auto"
            aria-label="spill av video"
            aria-controls="video-player"
            @click="toggleVideoPlay"
            ><template #prepend>
              <UIcon name="play-filled" size="lg" class="p-2"
            /></template>
          </UButtonNew>
          <video
            v-if="video.metadata.elementURI"
            id="video-player"
            ref="videoElement"
            preload="auto"
            :captions="video.captions"
            controls
            controlsList="nodownload"
            :poster="video.thumbnail"
            crossorigin="anonymous"
            playsinline
            :src="video.metadata.elementURI"
            tabindex="0"
            class="object-contain w-full h-full my-auto bg-black rounded-2xl"
            :autoplay="autoPlay"
            @loadeddata="onLoad"
            @play="onPlay"
            @timeupdate="checkProgress"
            @pause="onPause"
            @ratechange="onRateChange"
          >
            <track
              v-for="caption in video.captions"
              :key="caption.captionFile.uri"
              :src="caption.captionFile.uri"
              :srclang="caption.language"
              :label="toDisplayLanguage(caption.language)"
            />
            <span
              v-if="showTime && !getMinutes()?.includes('NaN')"
              class="absolute hidden p-2 text-2xl font-extrabold rounded-lg national-2 left-4 top-4 bg-darker text-platform-white md:block"
              >{{ getMinutes() }}</span
            >
            <span class="sr-only">minutter lengde</span>
          </video>
        </div>
      </div>
      <UVideoTranscription
        v-if="showTranscription"
        id="transcription"
        class="hidden @xl:flex"
        :transcription="video.transcription"
        :close="closeTranscription"
      />
    </div>
    <div
      class="flex flex-wrap flex-col items-start self-start justify-between gap-4 @xl:flex-row-reverse"
    >
      <div class="flex flex-col @xl:flex-row w-full @xl:w-fit">
        <UButtonNew
          :theme="isDark ? 'light' : 'black'"
          size="sm"
          variant="quite"
          data-pendo="video-transcription"
          @click="showTranscription = !showTranscription"
        >
          <template #prepend>
            <UIcon name="align-left" />
          </template>
          {{
            showTranscription
              ? "Skjul transkripsjon"
              : "Vis transkripsjon"
          }}
        </UButtonNew>
        <UVideoTranscription
          v-if="showTranscription"
          id="transcription"
          class="@xl:hidden"
          :transcription="video.transcription"
          :close="closeTranscription"
          :is-dark="isDark"
        />
      </div>
      <div
        v-if="video.timestamps.length"
        class="@sm:max-w-[70%] @lg:max-w-[50%] @md:w-[60vw] w-full flex flex-wrap gap-4"
      >
        <UButtonNew
          v-for="timestamp in video.timestamps"
          :key="timestamp.id"
          theme="translucent-light"
          pill
          size="sm"
          variant="filled"
          @click="handlePlayClick(timestamp.time)"
        >
          {{ timestamp.time }} {{ timestamp?.description }}
        </UButtonNew>
      </div>
    </div>
  </section>
</template>
