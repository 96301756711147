import type {
  MarketingArticleContent,
  MarketingArticleSectionContent,
} from "../types/MarketingArticle"
import { ContentType } from "~/models/Content/ContentType"
import { getContent } from "~/api/getContent"
import { PageData, PageSection } from "~/types/pages"
import { BaseItem } from "~/models/Content/BaseItem"

export interface MarketingAndPages {
  pages: PageData[]
  marketingArticles: MarketingArticleContent[]
}

const VITE_CMS_PAGES_LOCATION_ID = import.meta.env.VITE_CMS_PAGES_LOCATION_ID as string | "0"

export async function getMarketingAndPages(): Promise<MarketingAndPages> {
  const items = await getContent(
    {
      parentLocationIdCriterion: [
        parseInt(VITE_CMS_PAGES_LOCATION_ID),
      ],
      sortField: "priority",
    },
    { includeChildren: true, maxDepth: 3 },
  )

  // Format pages
  const pages = filterByContentType(items, ContentType.Page).map(
    mapPageSections,
  ) as PageData[]

  // Format marketing articles
  const folders = filterByContentType(items, ContentType.Folder)
  const marketingArticles = folders.flatMap(
    getMarketingArticlesFromFolder,
  ) as MarketingArticleContent[]

  return {
    pages,
    marketingArticles,
  }
}

const filterByContentType = (
  items: BaseItem[],
  contentType: ContentType,
): BaseItem[] =>
  items.filter(item => item.contentTypeIdentifier === contentType)

const mapPageSections = (page: BaseItem): PageData => ({
  ...(page as PageData),
  sections: page.children?.filter(
    child => child.contentTypeIdentifier === ContentType.PageSection,
  ) as PageSection[],
})

const mapMarketingArticleSections = (
  article: MarketingArticleContent,
): MarketingArticleContent => ({
  ...article,
  sections: (article.children?.filter(
    section =>
      section.contentTypeIdentifier ===
      ContentType.MarketingArticleSection,
  ) || []) as MarketingArticleSectionContent[],
})

const getMarketingArticlesFromFolder = (
  folder: BaseItem,
): MarketingArticleContent[] => {
  const marketingArticles =
    (folder.children?.filter(
      child =>
        child.contentTypeIdentifier === ContentType.MarketingArticle,
    ) as MarketingArticleContent[]) || []

  return marketingArticles.map(mapMarketingArticleSections)
}
