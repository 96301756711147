<script setup lang="ts">
import { formatForURL } from "~/utilities/composables"
import type { Folder } from "~/types/folder"
import type { Subject } from "~/types/subject"

const props = defineProps({
  chapters: {
    type: Object as PropType<Subject[]>,
    required: true,
    default: [] as Subject[],
  },
  folder: {
    type: Object as PropType<Folder>,
    required: true,
    default: () => ({}),
  },
})

const route = useRoute()
const router = useRouter()

const hash = computed(() => formatForURL(props.folder.title))
const isOpen = computed(() => {
  return route.hash.slice(1) === hash.value
})

function closeIfOpen(event: Event) {
  if (isOpen.value) {
    router.replace({ hash: "" })
    event.preventDefault()
  }
}

const classes = useThemedClasses(() => props.folder.colorTag)

const mediaCounts = useMediaCounts(() => props.folder)

const isFolderEmpty = computed(() => {
  const counts = mediaCounts.data.value
  return (
    !!counts &&
    counts.articles === 0 &&
    counts.videos === 0 &&
    counts.quizes === 0
  )
})
</script>

<template>
  <div
    v-if="
      (!isFolderEmpty && !route.hash) ||
      (isOpen &&
        route.fullPath.includes(route.fullPath.split('#')[1]))
    "
    class="relative"
    :class="classes.shadow()"
  >
    <div
      :class="[
        {
          'btn-wrapper mb-20 ml-4': !isOpen,
        },
      ]"
    >
      <a
        :id="`folder-card-${folder.id}`"
        data-pendo="folder-card"
        class="sticky z-20 flex flex-col justify-between h-full gap-4 px-12 py-8 overflow-hidden xl:gap-10 xl:px-16 xl:py-10"
        :class="{
          [`shadow-xl ${classes.background()}`]: !isOpen,
          'pl-[10px] pt-[24px] md:py-0 bg-none': isOpen,
          'min-h-[250px] xl:min-h-[465px]':
            !folder.img?.src?.length && !isOpen,
        }"
        :href="`#${formatForURL(folder.title)}`"
        @click="closeIfOpen($event)"
      >
        <h2
          :id="formatForURL(folder.title)"
          :class="isOpen ? 'mb-2 text-platform-paper' : classes.text()"
          class="mb-10 line-clamp-2 w-fit font-national2compressed text-4xl font-bold uppercase leading-none md:text-5xl lg:text-6xl xl:text-[96px]"
        >
          {{ folder.priority }}
          <span
            v-if="folder.titleHtml"
            class="inline"
            v-html="folder.titleHtml"
          />
          <span v-else>{{ folder.title }}</span>
        </h2>
        <div
          v-if="!isOpen"
          class="flex flex-col-reverse gap-3 xl:flex-row xl:items-end xl:gap-14"
          :class="{ 'content-wrapper': folder.img?.src?.length }"
        >
          <img
            v-if="folder.img?.src"
            :src="folder.img.src"
            :alt="folder.img.alt"
            class="w-96 xl:w-64 2xl:w-96"
          />
          <u-media-types
            :has-color-schema="props.folder.colorTag.length > 0"
            :data="folder"
            :class="classes.text()"
          />
        </div>
      </a>
      <ul v-if="isOpen" class="pt-4">
        <li
          v-for="(chapter, key) in chapters"
          :id="`chapter-card-list-item-${chapter.id}`"
          :key="key"
          class="mb-10 lg:mb-14"
        >
          <UChapterCard :chapter="chapter" />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.btn-wrapper {
  &:before {
    content: "";
    background-color: var(--shadow-left);
    transform: skewY(-40deg);
    @apply absolute left-[-13px] top-[0.78rem] h-[calc(100%+1px)] w-[29px];
  }

  &:after {
    content: "";
    transform: skew(-50deg);
    background-color: var(--shadow-bottom);
    width: calc(100% - 16px);
    @apply absolute bottom-[-25px] left-[1.4px] z-10 h-[25px];
  }
}
</style>
