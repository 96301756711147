<script setup lang="ts">
defineProps({
  position: {
    type: String as PropType<"top" | "bottom">,
    default: "top",
  },
})

const { catalogueFilteredByEAN } = useEcommerceCatalogue()

const products = useProductVariantsStore()

//Different product states
const productReleased = computed(() => {
  if (products.activeVariant) {
    return products.activeVariant.isReleased
  } else {
    return products.productVariants.some(p => p.isReleased)
  }
})

const productImage = computed(
  () =>
    catalogueFilteredByEAN(
      products.activeProduct?.variants.find(v => v.ean)?.ean,
    )?.images[0],
)
const windowAvailWidth = ref(window.screen.availWidth)

window.addEventListener("resize", () => {
  windowAvailWidth.value = window.screen.availWidth
})

const emit = defineEmits(["mounted"])
onMounted(() => {
  emit("mounted")
})
onBeforeMount(() => {
  window.scrollTo(1, 0)
})
</script>

<template>
  <section
    class="flex items-center justify-center w-full"
    :class="{ 'bg-[--theme-lighter]': position === 'bottom' }"
  >
    <div
      class="max-w-[90em]"
      :class="{
        'grid-cols-2 place-items-center gap-6 flex flex-col lg:grid':
          position === 'top',
        'flex items-center justify-center': position === 'bottom',
      }"
    >
      <figure
        v-if="position === 'top'"
        class="lg:order-2 flex items-center justify-center max-w-[248px] md:max-w-[400px] lg:max-w-[465px] lg:self-start"
      >
        <img
          v-if="productImage"
          loading="eager"
          :src="productImage.url"
          class="w-fit max-w-full rounded-lg object-contain"
          :class="{
            'rounded-2xl': !productImage,
          }"
          :alt="
            productImage.altText ||
            `Produktillustrasjon for ${products.activeProduct?.productTitle} `
          "
        />
      </figure>
      <div
        v-if="products.activeProduct"
        class="flex flex-col gap-5 lg:gap-10 lg:px-0"
        :class="{
          'text-center ': position === 'bottom',
          'text-left ': position === 'top',
        }"
      >
        <div v-if="position === 'top'">
          <h2
            class="font-numeraWebRegular text-pretty hyphens-auto text-center lg:text-left text-marketing-mobile-h2-h2-caption text-[--theme-darkest] md:text-marketing-desktop-h2-h2-caption xl:gap-10"
            v-html="products.activeProduct?.header"
          ></h2>

          <h3
            v-if="!productReleased"
            class="font-national2condensed text-center lg:text-left text-marketing-mobile-p1-p1-regular-m lg:text-marketing-desktop-p1-p1-regular-s text-[--theme-dark]"
          >
            Produktet lanseres snart, da kan du prøve oss helt gratis
          </h3>
        </div>

        <UProductActions
          v-if="products.activeProduct"
          :product="products.activeProduct"
          view-type="detail"
          show-status-details
          :align="position === 'bottom' ? 'center' : 'left'"
        />
      </div>
    </div>
  </section>
</template>
