import { loginPageUrl } from "~/utilities/urls"

export function useLoginPageUrl() {
  const route = useRoute<"product">()

  return computed(() => {
    if (route.params.product) {
      return loginPageUrl(route.params.product)
    } else {
      return undefined
    }
  })
}
