<script setup lang="ts">
import { ProductsList } from "~/models/Content/ContentProducts"

const props = defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: false,
    default: undefined,
  },
  dark: {
    type: Boolean,
    default: true,
  },
  headerLevel: {
    type: String,
    default: "h1",
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  aschehoug: {
    type: Boolean,
    default: false,
  },

  small: { type: Boolean, default: false },
})

const products = useProductVariantsStore()

const route = useRoute<"product">()

const logoUrl = computed(() => {
  if (props.aschehoug) {
    return "https://www.aschehoug.no"
  } else if (typeof route.query.fromPath === "string") {
    return route.query.fromPath
  } else if (
    products.activeProduct &&
    products.activeVariant &&
    products.activeVariant.isReleased
  ) {
    return (
      "/produkter/" +
      products.activeProduct.urlFormatProductTitle +
      "/" +
      products.activeVariant.variantKey
    )
  } else if (products.activeProduct) {
    return (
      "/produkter/" + products.activeProduct.urlFormatProductTitle
    )
  } else {
    return "/"
  }
})

const variantStore = useProductVariantsStore()
const title = computed(
  () =>
    props.product?.productTitleHtml ||
    props.product?.productTitle ||
    variantStore.activeProduct?.productTitle ||
    "",
)
</script>

<template>
  <component
    :is="headerLevel"
    class="max-w-[175px] font-national2compressed text-[32px] font-extrabold uppercase leading-[1em] md:max-w-[415px] md:text-5xl lg:text-[56px]"
    :class="{ 'min-w-[300px] !text-5xl lg:min-w-[400px]': small }"
  >
    <a
      :href="logoUrl"
      class="relative"
      :class="{
        'text-5xl text-white lg:text-6xl':
          (!title && !aschehoug) || (!product && !aschehoug),
        '!text-white after:!bg-white': !dark,
        '!text-[--theme-dark] after:!bg-[--theme-light]': dark,
      }"
      ><div v-if="!aschehoug">
        Kunne
        <span
          v-if="props.product?.productTitleHtml && product"
          v-html="title"
        />
        <span v-else-if="product">{{ title }}</span>
      </div>
      <UIcon v-else size="xl" name="aschehoug" />
    </a>
  </component>
</template>
