<script setup lang="ts">
import { ComponentPublicInstance } from "vue"

const FASIT_PATH = "#fasit"
const route = useRoute<"article">()

const showSolution = computed(() => route.hash !== FASIT_PATH)
const showSuggestion = ref(false)
const suggestionButtonRef = ref<ComponentPublicInstance | null>(null)

const isSmallScreen = useMediaQuery("(max-width: 744px)")

function handleSuggestionClick() {
  showSuggestion.value = !showSuggestion.value
  nextTick(() => {
    if (suggestionButtonRef.value !== null) {
      suggestionButtonRef.value?.$el.scrollIntoView({
        behavior: "smooth",
      })
    }
  })
}
</script>

<template>
  <div class="flex flex-col-reverse justify-between py-3 lg:flex-row">
    <UButtonNew
      ref="suggestionButtonRef"
      data-pendo="math-task-suggestion"
      pill
      variant="outline"
      :aria-expanded="!showSuggestion"
      aria-controls="suggestion"
      :size="isSmallScreen ? 'sm' : 'md'"
      @click="handleSuggestionClick"
    >
      <template #prepend>
        <u-icon :name="`chevron-${showSuggestion ? 'up' : 'down'}`" />
      </template>
      {{ showSuggestion ? "Skjul" : "Vis" }}
      løsningsforslag</UButtonNew
    >
    <UButtonNew
      data-pendo="math-task-solution"
      variant="quite"
      :href="FASIT_PATH"
      class="font-normal"
      :size="isSmallScreen ? 'sm' : 'md'"
      >{{ showSolution ? "Vis" : "Skjul" }} fasit</UButtonNew
    >
  </div>
  <UMathTaskSuggestion v-if="showSuggestion" id="suggestion" />
</template>
