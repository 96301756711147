<script setup lang="ts">
import type { NavProps } from "~/types/nav.d"
import type { Folder } from "~/types/folder"

import { ProductInformation } from "~/models/Content/ContentProducts"

/**
 * @see https://github.com/vuejs/core/issues/4294#issuecomment-896082769
 * import type { NavProps } from '~/types/nav.d'
import { useRoute } from '../../../../typed-router';
 * withDefaults(defineProps<NavProps>(), {})
 */

defineProps({
  folders: {
    type: Array as PropType<Folder[]>,
    default: () => [],
  },
  product: {
    type: Object as PropType<ProductInformation>,
    required: true,
    default: () => ({}),
  },
  data: {
    type: Array as PropType<NavProps["data"]>,
    required: true,
    default: () => [],
  },
  listType: {
    type: String as PropType<NavProps["listType"]>,
    default: "ul",
  },
  pageType: {
    type: String as PropType<string>,
    default: "subject",
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const products = useProductVariantsStore()

const showSearch = ref(false)
</script>

<template>
  <nav
    id="navigation"
    class="navigation sticky left-4 top-0 h-full w-screen bg-[--theme-darkest] text-white backdrop-blur-sm sm:min-w-[14em] md:max-w-[24em] <md:flex <md:items-center <md:justify-center <md:pb-5"
  >
    <div class="flex flex-col items-center justify-center">
      <div v-if="!showSearch" class="flex w-full h-full">
        <ULogo
          :product="products.activeProduct"
          aria-label="Gå tilbake kapitteloversikten"
          full-width
          small
          class="pt-12 <md:hidden"
          :dark="false"
        />
      </div>
      <div
        class="flex items-center justify-center w-full py-12"
        :class="{ '<md:hidden': !showSearch }"
      >
        <button
          v-if="showSearch"
          class="flex-none w-12 h-12 p-2 mr-2 border-white rounded-full md:hidden"
          @click="showSearch = false"
        >
          <u-icon
            class="ml-[3px] stroke-white text-white"
            name="close"
            size="sm"
          />
        </button>
        <u-search-bar class="w-full" />
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <u-nav-list
        v-if="data.length"
        :data="data"
        :page-type="pageType"
        :folders="folders"
        class="h-fit w-full pb-24 md:h-[calc(85svh-6rem)] <md:hidden"
        :licence="licence"
      >
        <template #list-top>
          <slot name="list-top" />
        </template>
      </u-nav-list>
      <u-nav-list-skeleton v-else class="hidden md:block" />
    </transition>
  </nav>
</template>

<!-- @apply mx-[-1.25rem] md:px-[1.25rem]; -->
<style scoped>
.navigation {
  @apply z-30;

  .top {
    @apply <md:grid <md:grid-cols-[max-content,1fr];
  }
}
.bottom {
  @apply absolute bottom-1 hidden py-5;
  @apply md:block;
}
</style>
