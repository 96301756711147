import { useQuery } from "@tanstack/vue-query"
import { Ref } from "vue"

export function useCheckoutPolling(
  hasTimedOut: Ref<boolean>,
  interval: number,
  enabled: Ref<boolean>,
) {
  const sessionId = useLocalStorage<string | null>("session_id", null)

  const isQueryEnabled = computed(
    () => enabled.value && !hasTimedOut.value && !!sessionId.value,
  )

  const query = useQuery({
    queryKey: ["checkout", sessionId],
    enabled: isQueryEnabled,
    async queryFn() {
      const response = await api.ecommerce.get(
        `/rest/checkout/${sessionId.value}`,
      )
      return response.data
    },
    refetchInterval: query => {
      // TODO: Are there other states that should be checked here?
      if (
        query.state.data?.sessionStatus === "NEW" ||
        query.state.data?.sessionStatus === "AUTHORIZED"
      ) {
        return interval
      }

      return false
    },
    select: data => data?.sessionStatus === "REJECTED",
  })

  const isPolling = computed(
    () => isQueryEnabled.value && query.data.value === undefined,
  )

  return {
    data: query.data,
    error: query.error,
    isLoading: isPolling,
  }
}
