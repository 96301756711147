<script setup lang="ts">
import { ProductsList } from "~/models/Content/ContentProducts"

const products = useProductVariantsStore()

const productVariantsByStatus = computed(() => {
  const productVariantsStatus: {
    comingSoon: ProductsList[]
    earlyAccess: ProductsList[]
    released: ProductsList[]
  } = {
    comingSoon: [],
    earlyAccess: [],
    released: [],
  }

  for (const product of products.productsList) {
    const doesProductHaveEan = product.variants.some(
      variant => variant.ean,
    )

    const hasAllProductVariantsComingSoon = product.variants.every(
      variant => variant.status === "coming-soon",
    )

    const hasProductWithReleasedStatus =
      product.productGroupStatus?.some(
        statusObj =>
          statusObj.status === "full-release" ||
          statusObj.status === "partial-release",
      )

    if (doesProductHaveEan && hasProductWithReleasedStatus) {
      productVariantsStatus.released.push(product)
    } else if (hasAllProductVariantsComingSoon) {
      productVariantsStatus.comingSoon.push(product)
    } else if (isFeatureEnabled.value) {
      productVariantsStatus.comingSoon.push(product)
    }
  }

  return productVariantsStatus
})
</script>

<template>
  <div class="space-y-16">
    <section class="text-center">
      <h3
        id="released-products"
        class="mb-7 font-national2condensed text-[32px] font-medium md:text-5xl text-left"
      >
        Våre produkter
      </h3>
      <ul
        v-if="productVariantsByStatus.released.length > 0"
        aria-labelledby="released-products"
      >
        <li
          v-for="released in productVariantsByStatus.released"
          :key="released.productTitle"
          class="pb-8 text-left last:pb-0"
        >
          <UProductCard :product="released" />
        </li>
      </ul>
      <p
        v-else
        class="font-national2condensed text-[16px] font-normal md:text-2xl"
      >
        Vi fant dessverre ingen produkter, prøv igjen senere
      </p>
    </section>
    <section v-if="productVariantsByStatus.comingSoon.length > 0">
      <h3
        id="coming-soon-products"
        class="text-4xl font-medium mb-7 font-national2condensed md:text-5xl"
      >
        Kommer snart
      </h3>
      <ul aria-labelledby="coming-soon-products">
        <li
          v-for="comingSoon in productVariantsByStatus.comingSoon"
          :key="comingSoon.productTitle"
          class="pb-8 last:pb-0"
        >
          <UProductCard :product="comingSoon" :released="false" />
        </li>
      </ul>
    </section>
  </div>
</template>
