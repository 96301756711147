<script setup lang="ts">
import {
  baseSellingPoints,
  additionalSellingPoints,
} from "../../utilities/sellingPoints"

const highlightedStyle =
  "font-national2 italic text-[--theme-translucent-dark]"

const sellingPoints = [
  ...baseSellingPoints,
  ...Object.values(additionalSellingPoints),
]
</script>

<template>
  <section
    class="flex max-w-[960px] flex-col gap-8 text-center font-numeraWebRegular text-xl font-normal text-[--theme-darker] [&>p]:leading-[30px] md:[&>p]:text-2xl md:[&>p]:leading-9"
  >
    <h2
      class="w-full text-center font-national2condensed text-[40px] font-medium leading-[44px] text-[--theme-dark] md:text-[72px] md:leading-[80px]"
    >
      Kunne hjelper studenter <br />
      å forstå pensum
    </h2>
    <p>
      Er du student og vil
      <b :class="highlightedStyle">forstå</b> pensum på en
      <b :class="highlightedStyle">effektiv</b>
      og
      <b :class="highlightedStyle">oversiktlig</b>
      måte? Da bør du sjekke ut Kunne.
    </p>
    <p>
      Alle våre kurs er laget av erfarne forelesere og forfattere, i
      samarbeid med
      <em>Universitetsforlaget, Aschehougs akademiske forlag</em>.
    </p>

    <USellingPoints
      :selling-points="sellingPoints"
      bg-color="bg-[--theme-lighter]"
      text-color="text-[--theme-medium]"
    />
  </section>
</template>
