<script setup lang="ts">
import { useTemplateRef } from "vue"
import { isColorDark } from "~/utilities/colorUtils"
import { keys } from "~/utilities/injection"

const ref = useTemplateRef("article")

const isDark = computed(() => {
  if (!ref.value) {
    return false
  }

  const { backgroundColor } = window.getComputedStyle(ref.value)

  return isColorDark(backgroundColor) === "dark"
})

provide(keys.isDark, isDark)

const article = inject(keys.currentArticle)
</script>

<template>
  <article
    v-if="article"
    :id="`template-${article.id}`"
    ref="article"
    class="u-grid u-article"
    :class="{
      // TODO: This only works due to the safe list logic. Should be refactored to somehow use static classes if possible.
      [`bg-${article.bg} text-${article.color}`]: !!article.bg,
    }"
  >
    <slot />
  </article>
</template>
