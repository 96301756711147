import type { MaybeRefOrGetter } from "vue"
import { tv } from "tailwind-variants"
import { picklist, parse, fallback } from "valibot"
import type { InferOutput } from "valibot"

const colorSchemeSchema = picklist([
  "britt",
  "arne",
  "jakob",
  "tom",
  "kjersti",
  "asle",
  "silje",
  "karina",
  "inger",
  "svein",
  "per",
  "sandra",
  "thor",
  "marit",
  "hugo",
  "hans",
  "ellen",
  "knut",
  "viggo",
  "tore",
  "ruth",
  "gro",
  "randi",
  "synne",
  "philip",
  "kirsti",
  "gerda",
  "tobias",
  "berit",
  "ida",
  "bjarne",
  "default",
])

export type ColorScheme = InferOutput<typeof colorSchemeSchema>

const variants = tv({
  slots: {
    text: "",
    bwText: "",
    background: "",
    shadow: "",
    gradient: "",
    gradientImage: "bg-gradient-to-t from-[--theme-gradient-from] to-transparent",
  },
  variants: {
    scheme: {
      britt: {
        text: "text-red-40",
        bwText: "text-white",
        background: "bg-dark-blue-80",
        shadow: "[--shadow-left:#1E429A] [--shadow-bottom:#16306F]",
        gradient: "bg-gradient-to-t from-dark-blue-100 to-dark-blue-80",
      },
      arne: {
        text: "text-dark-blue-60",
        bwText: "text-black",
        background: "bg-yellow-40",
        shadow: "[--shadow-left:#FFF195] [--shadow-bottom:#E8D346]",
        gradient: "bg-gradient-to-t from-yellow-60 to-yellow-40",
      },
      jakob: {
        text: "text-teal-20",
        bwText: "text-white",
        background: "bg-dark-blue-100",
        shadow: "[--shadow-left:#192F67] [--shadow-bottom:#0F1C3E]",
        gradient: "bg-gradient-to-t from-dark-blue-120 to-dark-blue-100",
      },
      tom: {
        text: "text-dark-blue-80",
        bwText: "text-black",
        background: "bg-grey-40",
        shadow: "[--shadow-left:#CCC8C2] [--shadow-bottom:#B0AAA1]",
        gradient: "bg-gradient-to-t from-grey-60 to-grey-40",
      },
      kjersti: {
        text: "text-yellow-20",
        bwText: "text-white",
        background: "bg-yellow-100",
        shadow: "[--shadow-left:#B98209] [--shadow-bottom:#886007]",
        gradient: "bg-gradient-to-t from-yellow-120 to-yellow-100",
      },
      asle: {
        text: "text-light-blue-100",
        bwText: "text-black",
        background: "bg-teal-20",
        shadow: "[--shadow-left:#D2F4F3] [--shadow-bottom:#ADEBEA]",
        gradient: "bg-gradient-to-t from-teal-40 to-teal-20",
      },
      silje: {
        text: "text-yellow-20",
        bwText: "text-white",
        background: "bg-light-blue-100",
        shadow: "[--shadow-left:#0043A8] [--shadow-bottom:#002F75]",
        gradient: "bg-gradient-to-t from-light-blue-100 to-light-blue-80",
      },
      karina: {
        text: "text-purple-80",
        bwText: "text-black",
        background: "bg-yellow-80",
        shadow: "[--shadow-left:#F7CE41] [--shadow-bottom:#BE9815]",
        gradient: "bg-gradient-to-t from-yellow-100 to-yellow-80",
      },
      inger: {
        text: "text-green-20",
        bwText: "text-white",
        background: "bg-dark-blue-80",
        shadow: "[--shadow-left:#1E429A] [--shadow-bottom:[#16306F]]",
        gradient: "bg-gradient-to-t from-dark-blue-100 to-dark-blue-80",
      },
      svein: {
        text: "text-red-80",
        bwText: "text-black",
        background: "bg-grey-20",
        shadow: "[--shadow-left:#F6F5F3] [--shadow-bottom:#DFD9D2]",
        gradient: "bg-gradient-to-t from-grey-40 to-grey-20",
      },
      per: {
        text: "text-purple-80",
        bwText: "text-black",
        background: "bg-purple-20",
        shadow: "[--shadow-left:#F1DAEC] [--shadow-bottom:#E2B6DA]",
        gradient: "bg-gradient-to-t from-purple-40 to-purple-20",
      },
      sandra: {
        text: "text-teal-80",
        bwText: "text-black",
        background: "bg-purple-20",
        shadow: "[--shadow-left:#F1DAEC] [--shadow-bottom:#E2B6DA]",
        gradient: "bg-gradient-to-t from-purple-40 to-purple-20",
      },
      thor: {
        text: "text-purple-20",
        bwText: "text-white",
        background: "bg-dark-blue-80",
        shadow: "[--shadow-left:#1E429A] [--shadow-bottom:#16306F]",
        gradient: "bg-gradient-to-t from-dark-blue-100 to-dark-blue-80",
      },
      marit: {
        text: "text-red-20",
        bwText: "text-white",
        background: "bg-red-80",
        shadow: "[--shadow-left:#D63114] [--shadow-bottom:#9E240F]",
        gradient: "bg-gradient-to-t from-red-100 to-red-80",
      },
      hugo: {
        text: "text-red-80",
        bwText: "text-black",
        background: "bg-purple-10",
        shadow: "[--shadow-left:#FEFBFD] [--shadow-bottom:#F4E1F0]",
        gradient: "bg-gradient-to-t from-purple-20 to-purple-10",
      },
      hans: {
        text: "text-green-100",
        bwText: "text-black",
        background: "bg-red-40",
        shadow: "[--shadow-left:#FFB5A4] [--shadow-bottom:#D8998A]",
        gradient: "bg-gradient-to-t from-red-60 to-red-40",
      },
      ellen: {
        text: "text-red-20",
        bwText: "text-white",
        background: "bg-green-100",
        shadow: "[--shadow-left:#324336] [--shadow-bottom:#1C261E]",
        gradient: "bg-gradient-to-t from-green-120 to-green-100",
      },
      knut: {
        text: "text-yellow-10",
        bwText: "text-white",
        background: "bg-red-100",
        shadow: "[--shadow-left:#922311] [--shadow-bottom:#65180C]",
        gradient: "bg-gradient-to-t from-red-120 to-red-100",
      },
      viggo: {
        text: "text-green-100",
        bwText: "text-black",
        background: "bg-yellow-60",
        shadow: "[--shadow-left:#FFE54D] [--shadow-bottom:#E5C400]",
        gradient: "bg-gradient-to-t from-yellow-80 to-yellow-60",
      },
      tore: {
        text: "text-teal-40",
        bwText: "text-white",
        background: "bg-dark-blue-80",
        shadow: "[--shadow-left:#1E429A] [--shadow-bottom:#16306F]",
        gradient: "bg-gradient-to-t from-dark-blue-100 to-dark-blue-80",
      },
      ruth: {
        text: "text-yellow-20",
        bwText: "text-white",
        background: "bg-grey-80",
        shadow: "[--shadow-left:#726A5A] [--shadow-bottom:#554F44]",
        gradient: "bg-gradient-to-t from-grey-100 to-grey-80",
      },
      gro: {
        text: "text-yellow-20",
        bwText: "text-white",
        background: "bg-teal-80",
        shadow: "[--shadow-left:#229190] [--shadow-bottom:#186767]",
        gradient: "bg-gradient-to-t from-teal-100 to-teal-80",
      },
      randi: {
        text: "text-teal-10",
        bwText: "text-white",
        background: "bg-dark-blue-60",
        shadow: "[--shadow-left:#2557D0] [--shadow-bottom:#1B419C]",
        gradient: "bg-gradient-to-t from-dark-blue-80 to-dark-blue-60",
      },
      synne: {
        text: "text-yellow-10",
        bwText: "text-white",
        background: "bg-green-60",
        shadow: "[--shadow-left:#4BA05A] [--shadow-bottom:#397A45]",
        gradient: "bg-gradient-to-t from-green-80 to-green-60",
      },
      philip: {
        text: "text-light-blue-80",
        bwText: "text-black",
        background: "bg-green-20",
        shadow: "[--shadow-left:#E1F5E4] [--shadow-bottom:#ACE2B5]",
        gradient: "bg-gradient-to-t from-green-40 to-green-20",
      },
      kirsti: {
        text: "text-yellow-20",
        bwText: "text-white",
        background: "bg-green-100",
        shadow: "[--shadow-left:#324336] [--shadow-bottom:#1C261E]",
        gradient: "bg-gradient-to-t from-green-120 to-green-100",
      },
      gerda: {
        text: "text-dark-blue-60",
        bwText: "text-black",
        background: "bg-teal-10",
        shadow: "[--shadow-left:#F7FDFD] [--shadow-bottom:#D6F5F3]",
        gradient: "bg-gradient-to-t from-teal-20 to-teal-10",
      },
      tobias: {
        text: "text-yellow-10",
        bwText: "text-white",
        background: "bg-purple-100",
        shadow: "[--shadow-left:#60205C] [--shadow-bottom:#3A1337]",
        gradient: "bg-gradient-to-t from-purple-120 to-purple-100",
      },
      berit: {
        text: "text-purple-20",
        bwText: "text-white",
        background: "bg-purple-80",
        shadow: "[--shadow-left:#95378B] [--shadow-bottom:#702969]",
        gradient: "bg-gradient-to-t from-purple-120 to-purple-80",
      },
      ida: {
        text: "text-dark-blue-60",
        bwText: "text-black",
        background: "bg-red-10",
        shadow: "[--shadow-left:#FEFBFB] [--shadow-bottom:#F6DDDA]",
        gradient: "bg-gradient-to-t from-red-20 to-red-10",
      },
      bjarne: {
        text: "text-black",
        bwText: "text-black",
        background: "bg-white",
        shadow: "[--shadow-left:white] [--shadow-bottom:white]",
        gradient: "bg-gradient-to-t from-grey-10 to-white",
      },
      default: {
        text: "text-white",
        bwText: "text-white",
        background: "bg-purple-100",
        shadow: "[--shadow-left:#755A71] [--shadow-bottom:#1F1A1E]",
        gradient: "bg-gradient-to-t from-[--theme-darker] to-[--theme-dark]",
      },
    } satisfies Record<ColorScheme, unknown>,
  },
})

export function getValidScheme(rawScheme: MaybeRefOrGetter<string | undefined | null>) {
  return parse(fallback(colorSchemeSchema, "default"), toValue(rawScheme)?.toLowerCase())
}

export function useThemedClasses(scheme: MaybeRefOrGetter<string | undefined | null>) {
  return computed(() => variants({
    scheme: getValidScheme(scheme),
  }))
}
