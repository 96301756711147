<script setup lang="ts">
import { getMinutesAndSeconds } from "~/utilities/getMinutesAndSeconds"
import type { Article } from "~/types/article"
import { keys } from "~/utilities/injection"

defineProps({
  withImage: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  firstAudioArticleUrl: {
    type: String as PropType<string>,
    default: () => null,
  },
})

const isDark = inject(keys.isDark)

const store = useProductContentStore()
const audioStore = useAudioStore()

const currentSubject = computed(() => store.activeSubject)
const subjectArticles = computed(() => store.articles)
const subject = ref()
const articles = ref()

const getLoadedArticles = () => subjectArticles.value || articles.value || []

const countTotalPlayTime = () => {
  return getLoadedArticles()
    .filter((e: Article) => e.audio)
    .reduce((acc: number, curr: Article) => {
      return acc + parseFloat(curr.audio.metadata.duration)
    }, 0)
}

const setPlayListSubject = () => {
  audioStore.setPlayListSubject(
    subject.value?.shortTitle || currentSubject.value?.shortTitle,
    subject.value?.priority || currentSubject.value?.priority,
    countTotalPlayTime(),
  )
}

const totalMinutesAndSeconds = computed(() =>
  getMinutesAndSeconds(countTotalPlayTime()),
)

const currentAudioMinutesAndSeconds = computed(() => {
  return getMinutesAndSeconds(
    store.activeArticle?.audio.metadata.duration,
  )
})

watch(currentSubject, () => {
  if (currentSubject.value) {
    subject.value = currentSubject.value
    if (audioStore.activePlayer) {
      setPlayListSubject()
    }
  }
})

watch(subjectArticles, () => {
  if (subjectArticles.value) {
    articles.value = subjectArticles.value
  }
})

const generateAudioData = () => {
  return getLoadedArticles()
    .filter((e: Article) => e.audio)
    .map((a: Article) => ({
      id: a.id,
      title: a.audio.title,
      url: a.audio.metadata.elementURI,
      duration: a.audio.metadata.duration,
      active: a.id === store.activeArticle?.id,
      img: a.img?.src,
    }))
}

const handlePlayer = () => {
  audioStore.togglePlayer()
  setPlayListSubject()
  audioStore.setPlayList(generateAudioData())
}
</script>

<template>
  <div v-if="store.activeArticle?.audio" class="audio-btn-wrapper">
    <UButtonNew
      aria-label="Lytt til artikkelen"
      variant="quite"
      :theme="isDark ? 'light' : 'medium'"
      class="!overflow-visible !p-0 active:border-2"
      @click="handlePlayer()"
    >
      <template #prepend>
        <u-icon
          name="headphones"
          class="rounded-full text-inherit"
          :class="[
            { 'animate-pulse': audioStore.activePlayer },
            {
              'inherited-colors bg-inherit text-inherit':
                !withImage && firstAudioArticleUrl,
            },
            { 'default-colors': withImage && firstAudioArticleUrl },
          ]"
        />
      </template>
      <p v-if="!audioStore.activePlayer" class="text-inherit">
        {{
          !firstAudioArticleUrl
            ? "Lytt til artikkelen"
            : "Lytt til kapittelet"
        }}
        <span v-if="firstAudioArticleUrl" class="font-normal duration"
          >({{ totalMinutesAndSeconds }} )</span
        >
        <span v-else class="font-normal duration"
          >{{ currentAudioMinutesAndSeconds }}
        </span>
      </p>
      <p v-if="audioStore.activePlayer" class="text-inherit">
        Lytter...
      </p>
    </UButtonNew>
  </div>
</template>

<style lang="postcss">
.audio-btn-wrapper {
  @apply flex max-h-[48px] gap-1;
  p {
    @apply !m-0 self-center font-national2 text-[22px] font-extrabold leading-8;
    .duration {
      @apply font-national2 font-normal normal-case;
    }
  }
}
</style>
