import { useQuery } from "@tanstack/vue-query"
import { getMarketingAndPages } from "~/api/getMarketingAndPages"

export function useMarketing() {
  return useQuery({
    queryKey: ["marketing"],
    staleTime: 60 * 60 * 1000, // 1 hour
    queryFn: getMarketingAndPages,
  })
}
