import type { Ref } from "vue"
import { Article } from "~/types/article"
import { getMathTaskSolutions } from "~/mapping/math-task/mathTasks"
import { keys } from "~/utilities/injection"
import { useQuery } from "@tanstack/vue-query"

export function useMathTask(articleData: Ref<Article>) {
  const mathTask = computed(() => articleData.value.mathTask)

  const solutions = useQuery({
    queryKey: [
      "math-task-solutions",
      computed(() => articleData.value.locationId),
    ] as const,
    enabled: computed(
      () =>
        !!mathTask.value &&
        isMathTemplate(articleData.value.template),
    ),
    queryFn: ({ queryKey }) => getMathTaskSolutions(queryKey[1]),
  })

  provide(keys.mathTask, mathTask)
  provide(keys.suggestedSolution, solutions.data)

  return solutions.data
}

type MathTemplates = "UMathTaskWithImageArticle" | "UMathTaskArticle"

function isMathTemplate(value: string): value is MathTemplates {
  return (
    value === "UMathTaskWithImageArticle" ||
    value === "UMathTaskArticle"
  )
}
