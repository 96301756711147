<script setup lang="ts">
import { storeToRefs } from "pinia"

const { catalogueFilteredByEAN } = useEcommerceCatalogue()

const { activeProduct: product, activeVariant } = storeToRefs(
  useProductVariantsStore(),
)

const productImage = computed(() => {
  return catalogueFilteredByEAN(activeVariant.value?.ean)?.images[0]
})
</script>

<template>
  <div class="space-y-5 md:max-w-xl">
    <div
      v-if="product"
      class="flex justify-center md:max-h-52 max-h-44"
    >
      <img
        v-if="productImage"
        :src="productImage.url"
        class="object-contain"
        :alt="
          productImage.altText ||
          `Produktillustrasjon for ${product.productTitle} `
        "
      />
      <div v-else></div>
    </div>
    <h2
      class="text-center font-national2condensed md:text-paywall-desktop-title text-paywall-mobile-title text-[--theme-dark]"
    >
      <span v-if="!activeVariant?.isFree"
        >Lås opp resten av kunnskapen!</span
      >
      <span v-else>Få kunnskapen først!</span>
    </h2>
  </div>
</template>
