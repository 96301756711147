<script setup lang="ts">
import { ContentFile } from "~/models/Content/schemas/media.schema"
const { file } = defineProps<{
  file: ContentFile
}>()

const fileSize = computed(() => {
  const KB = 1024
  const MB = KB * 1024

  if (file.binaryFile.fileSize >= MB) {
    return `${(file.binaryFile.fileSize / MB).toFixed(1)} MB`
  } else {
    return `${(file.binaryFile.fileSize / KB).toFixed(1)} kB`
  }
})

const fileExtension = computed(() => {
  const index = file.binaryFile.fileName.lastIndexOf(".")
  return index !== -1
    ? `.${file.binaryFile.fileName.slice(index + 1)}`
    : undefined
})
</script>
<template>
  <UButtonNew
    variant="outline"
    pill
    size="sm"
    data-pendo="file-download"
    :href="file.binaryFile.url"
  >
    <template #prepend><UIcon name="download" /></template>
    Last ned
    {{ file.name || file.binaryFile.fileName }}
    ({{ fileExtension }}, {{ fileSize }})
  </UButtonNew>
</template>
