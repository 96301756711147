<script setup lang="ts">
import type { Article } from "~/types/article"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const imageSize = computed(() => {
  switch (props.data.imageSize) {
    case "Small":
      return 4

    case "Medium":
      return 6

    case "Large":
    default:
      return 8
  }
})

const textClasses = computed(() => {
  const size =
    imageSize.value <= 4 ? imageSize.value + 1 : imageSize.value
  const start = 2
  const end = 16 - size + 2

  return `col-start-2 col-end-18 @md:col-start-${start} @md:col-end-${end} @lg:col-start-${
    start + 1
  } @lg:col-end-${end - 1} @2xl:col-start-${start} @2xl:col-end-${
    end - 1
  }`
})

const imageClasses = computed(() => {
  const start = 16 - imageSize.value + 2

  return `col-start-1 col-end-19 @md:col-start-${start} @md:col-end-19`
})
</script>

<template>
  <UArticle>
    <UArticleHeader
      :data="data"
      class="u-article__title order-1 pt-[var(--grid-col-width)] @md:order-none @md:row-start-1"
      :class="textClasses"
    />

    <section
      class="u-article__body order-3 pb-32 @md:order-none @md:row-start-2 flex flex-col gap-10"
      :class="textClasses"
    >
      <u-rich-text v-if="data.body" :html="data.body.html" />
      <UArticleFiles v-if="data.files" :files="data.files" />
    </section>

    <u-article-image
      :data="data"
      class="u-article__image relative order-2 @md:sticky @md:top-0 @md:order-none @md:row-span-2"
      :class="imageClasses"
    />
  </UArticle>
</template>
