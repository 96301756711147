import { InjectionKey, Ref } from "vue"
import { MathTask } from "~/types/article"
import type { Article } from "~/types/article"
import { AssignmentSolutions } from "~/api/getMathTasks"
import { Video } from "~/types/media"

export const keys = {
  suggestedSolution: Symbol("suggestedSolution") as InjectionKey<
    Ref<AssignmentSolutions | undefined>
  >,
  mathTask: Symbol("mathTask") as InjectionKey<
    Ref<MathTask | undefined>
  >,
  mathTaskSolutionVideo: Symbol(
    "mathTaskSolutionVideo",
  ) as InjectionKey<Ref<Video | undefined>>,
  currentArticle: Symbol("currentArticle") as InjectionKey<
    ComputedRef<Article>
  >,
  isDark: Symbol("isDark") as InjectionKey<ComputedRef<boolean>>,
  showVariantModal: Symbol("showVariantModal") as InjectionKey<Ref<boolean>>,
}
