<script setup lang="ts">
defineProps({
  preview: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  plainText: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  parentLocationId: Number,
  subjectPage: {
    type: Boolean,
    default: false,
  },
  isDark: {
    type: Boolean,
    default: false,
  },
})

const data = useProductContentStore()

const fields = computed(() => ({
  href: data.activeSubject?.frontendHref || data.activeSubject?.href,
  shortTitle: data.activeSubject?.shortTitle,
  priority: data.activeSubject?.priority,
}))
</script>

<template>
  <p
    v-if="fields.href && fields.shortTitle && !plainText"
    class="mb-2 line-clamp-2 font-national2 text-xl font-extrabold uppercase xl:text-2xl"
  >
    <RouterLink
      :to="fields.href"
      class="border-b-2 text-[--theme-dark] border-[--theme-dark]"
      :class="{
        '!border-lighter !text-lighter': isDark,
      }"
      >{{ fields.priority }}.
      {{ fields.shortTitle }}</RouterLink
    >
  </p>
  <p
    v-if="fields.href && fields.shortTitle && plainText"
    class="border-b-2 border-u-contrast"
  >
    {{ fields.priority }}.
    {{ fields.shortTitle }}
  </p>
</template>
