<script lang="ts" setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String as PropType<
      "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "auto"
    >,
    required: false,
    default: "md",
  },
})

const modules = import.meta.glob("../assets/icons/**/*.svg", {
  query: "?raw",
  import: "default",
  eager: true,
})

const svg = computed(() => {
  return modules["../assets/icons/" + props.name + ".svg"]
})
</script>

<template>
  <span
    v-if="name"
    :class="`icon ${size} all-unset block`"
    v-html="svg"
  />
</template>

<style>
.icon svg {
  width: auto;
}

.icon.xxs svg {
  height: 14px !important;
}

.icon.xs svg {
  height: 16px !important;
}

.icon.sm svg {
  height: 18px !important;
}

.icon.md svg {
  height: 20px !important;
}

.icon.lg svg {
  height: 24px !important;
}

.icon.xl svg {
  height: 48px !important;
}

.icon.auto svg {
  height: auto;
}
</style>
