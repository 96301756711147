<script setup lang="ts">
import { isAuthorized } from "~/utilities/auth"

const props = defineProps({
  mobile: Boolean,
  path: {
    type: Array as PropType<string[]>,
    required: true,
    default: () => [],
  },
})

const loginPageUrl = useLoginPageUrl()
const products = useProductVariantsStore()
const route = useRoute()

const isProfilePageActive = computed(() =>
  route.path.includes("min-side"),
)

const urlPrefix = `/produkter/${props.path.filter(Boolean).join("/")}`
</script>

<template>
  <div
    class="flex"
    :class="{
      '<md:!hidden  xl:pt-14 pt-12 items-center justify-end w-full':
        !mobile,
    }"
  >
    <UButtonNew
      v-if="isAuthorized"
      id="min-side"
      data-pendo="min-side-desktop"
      :to="
        isProfilePageActive
          ? `${urlPrefix}${route.hash}`
          : `${urlPrefix}/min-side${route.hash}`
      "
      theme="light"
      size="sm"
      :stacked="mobile"
      :active="isProfilePageActive && !mobile"
    >
      <template #prepend>
        <u-icon
          name="user"
          :class="{ 'mb-2': mobile }"
          :size="mobile ? 'lg' : 'sm'"
        />
      </template>
      Min side
    </UButtonNew>
    <UButtonNew
      v-else-if="loginPageUrl"
      id="logg-in"
      variant="outline"
      :class="{ '<md:!hidden mt-3': !mobile }"
      theme="light"
      size="sm"
      :stacked="mobile"
      :to="
        products.activeVariant
          ? `${loginPageUrl}?fromPath=${encodeURIComponent(route.fullPath)}`
          : loginPageUrl
      "
    >
      <template #prepend>
        <UIcon
          name="user"
          :class="{ 'mb-2': mobile }"
          :size="mobile ? 'lg' : 'sm'"
        />
      </template>
      Logg inn
    </UButtonNew>
  </div>
</template>
