<script setup lang="ts">
import { Article } from "~/types/article"
import UIcon from "~/components/UIcon.vue"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const productContent = useProductContentStore()
const assignmentType = computed(
  () => props.data.mathTask?.assignmentType,
)

const iconName = computed(() => {
  switch (assignmentType.value) {
    case "Mengdetrening":
      return "cube-multiple"
    case "Innlæring":
    default:
      return "cube"
  }
})

const illustrationLine = `
<svg
    width="3"
    height="100%"
    viewBox="0 0 3 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
>
    <path
        d="M1.01074 1.01819L1.01075 76.6909"
        stroke="${assignmentType.value === "Mengdetrening" ? "var(--theme-light)" : "var(--theme-dark)"}"
        stroke-width="2"
        stroke-linecap="round"
    />
</svg>`

const introduction = computed(() => {
  const text =
    props.data.body?.html || props.data.mathTask?.assignments[0]?.text
  const match = text?.match(/<p[^>]*>.*?<\/p>/s)
  return match ? match[0] : undefined
})

const isDesktop = useMediaQuery("(min-width: 1024px)")
</script>

<template>
  <div
    class="grid h-full w-full grid-cols-5 overflow-clip rounded-xl bg-platform-paper font-national2condensed text-black"
    :class="{
      'brightness-[85%]': data.restricted,
    }"
  >
    <div
      class="col-start-1 col-end-2 gap-4 lg:gap-7 <lg:h-[231px] flex flex-col items-center justify-between px-3 py-4 lg:py-14"
      :class="
        assignmentType === 'Mengdetrening'
          ? 'bg-[--theme-dark]'
          : 'bg-[--theme-lighter]'
      "
    >
      <span class="h-[18%] lg:h-auto" v-html="illustrationLine" />
      <UIcon :name="iconName" :size="isDesktop ? 'auto' : 'xl'" />
      <span class="h-[45%] lg:h-[80%]" v-html="illustrationLine" />
    </div>
    <div
      class="col-start-2 col-end-6 flex flex-col w-full justify-center px-5 self-stretch items-start gap-5 bg-platform-paper text-left lg:pl-14 lg:pr-28 lg:pb-20 lg:pt-14"
    >
      <section
        class="order-2 flex flex-col justify-center items-start w-full"
      >
        <h4
          v-if="productContent.activeSubject"
          class="text-md hidden font-national2 text-[--theme-darker] font-extrabold uppercase lg:block lg:text-lg lg:pb-2"
        >
          {{
            +productContent?.activeSubject?.priority +
            " " +
            productContent?.activeSubject?.title
          }}
        </h4>
        <h3
          class="truncate whitespace-pre-wrap text-platform-black text-2xl font-medium lg:text-4xl xl:text-5xl hyphens-auto pb-2 lg:pb-4"
        >
          {{ data.title ?? "Laster..." }}
        </h3>
        <p
          v-if="!data.restricted && introduction"
          class="font-national2 text-base lg:text-lg overflow-ellipsis w-full pb-1 line-clamp-3 [&_*_strong]:font-normal"
          v-html="introduction"
        />

        <span
          v-if="data.restricted"
          class="font-national2 font-bold text-dark lg:text-lg"
        >
          - Tilgang krever lisens</span
        >
      </section>
      <UContentBadge
        v-if="assignmentType"
        :aria-label="`Oppgavetype: ${assignmentType}`"
        :content="assignmentType"
        class="order-1"
        :icon="
          assignmentType === 'Mengdetrening' ? 'repeat' : undefined
        "
      />
    </div>
  </div>
</template>
