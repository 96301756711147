<script setup>
useHead({
  bodyAttrs: {
    class: "bg-[--theme-lightest]",
  },
})
</script>

<template>
  <div
    class="text scroll-smooth bg-platform-paper text-[#1A3985]"
  >
    <!-- Header -->
    <UHeader back-button />
    <!-- Main content -->
    <main class="py-4 lg:pt-0">
      <router-view />
    </main>

    <UMarketingFooter class="bg-dark-blue-darker" />
  </div>
</template>
