<script setup lang="ts">
import { keys } from "~/utilities/injection"

const showVideo = ref(false)
const video = inject(keys.mathTaskSolutionVideo)
</script>

<template>
  <section v-if="video">
    <UButtonNew
      variant="quite"
      theme="medium"
      :class="{ 'mb-10': !showVideo }"
      :data-pendo="`video-suggested-solution'-${showVideo ? 'close' : 'open'}`"
      @click="showVideo = !showVideo"
    >
      <template #prepend>
        <UIcon
          :name="`${showVideo ? 'close' : 'play-filled'}`"
          size="xxs"
          class="rounded-full bg-[--theme-medium] text-white p-3"
      /></template>

      <span
        class="gap-4 pl-2 flex text-xl items-center text-[--theme-dark] font-bold"
      >
        {{ showVideo ? "Lukk" : "Se" }} videoforklaring
      </span>
    </UButtonNew>
    <UVideo
      v-if="showVideo"
      :video="video"
      :is-dark="false"
      auto-play
    />
  </section>
</template>
