<script setup lang="ts">
import * as Sentry from "@sentry/vue"
import { checkoutPageUrl } from "~/utilities/urls"
import { formatDate } from "~/utilities/composables"

interface LicenceProduct {
  ean: string
}

const { allLicences } = useLicences()

const { logout, isAuthorized, user } = useAuth()
const products = useProductVariantsStore()

const licenceType = computed(() => {
  return products.activeVariant?.userHasLicence
    ? "Betalt"
    : "Prøveversjon"
})

const router = useRouter()
const route = useRoute("subject")

const handleLicenceClick = (licenceProduct: LicenceProduct): void => {
  const variant = products.productVariants?.find(
    v => v.ean === licenceProduct.ean,
  )

  if (variant && variant.shortTitle) {
    router.push({
      path: `/produkter/${variant.shortTitle.toLocaleLowerCase()}/${variant.variantKey}`,
    })
  } else {
    Sentry.captureException(
      `could not find matching routing for [${variant?.shortTitle?.toLocaleLowerCase()}] with variant [${variant?.variantKey}] and EAN [${licenceProduct.ean}]`,
    )
  }
}

const { catalogueFilteredByEAN } = useEcommerceCatalogue()

const productImage = (ean?: string) => {
  return catalogueFilteredByEAN(ean)?.images[0]
}
</script>

<template>
  <div class="mb-20 rounded-xl pt-12 text-[--theme-lightest]">
    <div
      class="flex flex-col items-start justify-start w-full gap-4 font-numeraWebRegula"
    >
      <div
        class="flex items-center justify-between w-full gap-4 pb-6"
      >
        <h1
          class="flex items-center justify-center pl-6 text-5xl font-bold whitespace-nowrap font-national2compressed"
        >
          Min Side
        </h1>
      </div>
      <div v-if="isAuthorized" class="flex flex-col w-full gap-4">
        <div
          class="w-full p-6 overflow-hidden bg-white rounded-lg bg-opacity-10 2xl:p-12"
        >
          <div
            class="flex items-start justify-between gap-2 pb-2 <sm:items-center"
          >
            <div
              class="flex items-center justify-start gap-3 text-4xl"
            >
              <h2 class="text-xl lg:text-3xl">
                {{ user.fullName }}
              </h2>
            </div>
          </div>
          <ul class="user-info whitespace-nowrap">
            <li
              class="flex gap-4 border-b-2 border-b-white border-opacity-20 pt-2 <md:flex-col <md:gap-0"
            >
              <p class="flex items-center justify-center field-title">
                Lisens:
              </p>
              <div
                class="flex items-center justify-between w-full field-text"
              >
                <p>
                  {{ `Kunne ${products.activeVariant?.name}` }}
                </p>
                <div class="flex items-center justify-center gap-2">
                  <p class="font-national2">
                    {{ licenceType }}
                  </p>
                  <u-icon
                    v-if="licenceType === 'Betalt'"
                    name="check"
                    class="scale-[0.5] rounded-full bg-green-60 p-2"
                  />
                </div>
              </div>
            </li>
            <li
              v-if="allLicences.length > 0 && isAuthorized"
              class="flex gap-4 border-b-2 border-b-white border-opacity-20 <md:flex-col <md:gap-0"
            >
              <p class="field-title">Betaling:</p>
              <p class="field-text">Vipps</p>
            </li>
            <li
              class="flex items-center justify-between gap-4 pt-2 <md:flex-col <md:items-start <md:gap-2"
            >
              <div class="flex w-full gap-4 md:w-[50%]">
                <p class="field-title">E-post:</p>
                <p class="field-text w-fit max-w-96">
                  {{ user.email }}
                </p>
              </div>
              <UButtonNew
                v-if="isAuthorized"
                size="sm"
                class="overflow-visible"
                theme="light"
                @click="logout(route.params.product)"
              >
                Logg ut
              </UButtonNew>
            </li>
          </ul>
        </div>
        <div
          class="w-full p-6 overflow-hidden bg-white rounded-lg h-fit bg-opacity-10 2xl:p-12"
        >
          <div class="flex items-center justify-start gap-4 pb-6">
            <h2 class="text-2xl whitespace-nowrap lg:text-3xl">
              Mine produkter
            </h2>
          </div>
          <div
            v-if="allLicences.length > 0"
            class="flex flex-col gap-4"
          >
            <div
              v-for="licenceProduct in allLicences"
              :key="licenceProduct.ean"
            >
              <div
                class="mb-10 flex flex-col items-start justify-start gap-4 hover:cursor-pointer hover:brightness-[85%] md:flex-row"
                tabindex="0"
                :aria-label="
                  'Bytt til produktet ' + licenceProduct.name
                "
                @click="() => handleLicenceClick(licenceProduct)"
              >
                <div
                  class="h-44 w-44 rounded bg-[--theme-lightest] object-contain p-2"
                >
                  <img
                    v-if="productImage(licenceProduct.ean)?.url"
                    :src="productImage(licenceProduct.ean)?.url"
                    class="w-full h-full"
                    :alt="
                      productImage(products.activeVariant?.ean)
                        ?.altText ||
                      `Produktillustrasjon for ${licenceProduct.name} `
                    "
                  />
                </div>
                <div class="flex flex-col gap-2">
                  <p class="font-bold font-national2"></p>
                  <p class="font-bold font-national2">
                    {{ licenceProduct.name }}
                  </p>
                  <p class="font-light font-national2">
                    Tilgang til
                    {{ formatDate(licenceProduct.endDate) }}
                    (utløper automatisk)
                  </p>
                  <p />
                </div>
              </div>
            </div>
          </div>
          <p v-else>
            Vi fant ingen betalte produkter tilkoblet brukeren din.
            <a
              element-type="router-link"
              custom-attr="to"
              :href="checkoutPageUrl(route.params.product)"
              class="text-u-contrast"
              data-pendo="min-side-kjop-tilgang"
              >Kjøp tilgang</a
            >
          </p>
        </div>
        <!-- <u-download-offline /> -->
        <u-feature-flag>
          <u-settings />
        </u-feature-flag>
        <div
          class="w-full p-6 overflow-hidden bg-white rounded-lg h-fit bg-opacity-10 2xl:p-12"
        >
          <h2 class="pb-6 text-2xl whitespace-nowrap lg:text-3xl">
            Kundeservice
          </h2>
          <p class="flex items-center justify-start gap-4">
            Trenger du hjelp?
            <UButtonNew
              size="sm"
              theme="light"
              href="/om/kundeservice"
            >
              <template #append>
                <u-icon name="link-external" size="sm" />
              </template>
              Besøk våre hjelpesider
            </UButtonNew>
          </p>

          <UCurrentVersion />
        </div>
      </div>
      <div
        v-else
        class="flex items-center justify-start w-full h-full pb-10 pl-2"
      >
        Logg inn for å se informasjon om brukeren din
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.user-info li {
  @apply py-2;
}

.field-title {
  @apply w-fit font-numeraWebBold text-base font-bold;
}

.field-text {
  @apply overflow-hidden overflow-ellipsis font-national2 <sm:w-[250px];
}
</style>
