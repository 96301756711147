<script setup lang="ts">
import { MathTaskAssignment } from "~/types/article"
import { keys } from "~/utilities/injection"

const props = defineProps({
  assignment: {
    type: Object as PropType<MathTaskAssignment>,
    required: true,
  },
  showSuggestions: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showAssignmentInSuggestion: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
})

const suggestedSolutionsData = inject(keys.suggestedSolution)
const hideAssignmentText =
  props.showSuggestions && !props.showAssignmentInSuggestion
</script>
<template>
  <div class="text-[18px] md:text-[24px] [&>*]:font-national2">
    <u-rich-text
      v-show="!hideAssignmentText"
      :html="assignment.text"
    />
    <div
      :class="`${
        showSuggestions
          ? 'mt-3 rounded bg-green-lightest p-4 md:px-8 md:py-6 lg:rounded-lg'
          : ''
      }`"
    >
      <USuggestedSolutionSection
        v-if="showSuggestions"
        :sections="
          suggestedSolutionsData?.assignments.get(assignment.id)
            ?.suggestedSolution.sections
        "
      />
    </div>
  </div>
</template>
