<script setup lang="ts">
import { onMountedDOMNode } from "~/utilities/dom"

const props = defineProps({
  scrollOfId: {
    type: String as PropType<string>,
    default: "",
  },
  scrollOf: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  scrollOfMobile: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  classOnScroll: {
    type: String as PropType<string>,
    default: "",
  },
  limitPosition: {
    type: Number as PropType<number>,
    default: 500,
  },
  lastPosition: {
    type: Number as PropType<number>,
    default: 0,
  },
  scrollOfSelectorType: {
    type: String as PropType<string>,
    default: "getElementById",
  },
})

const emit = defineEmits(["scrollOf"])

const limitPosition = ref(props.limitPosition || 500)
const scrolled = ref(false)
const lastPosition = ref(props.lastPosition || 0)
const el = ref({}) as Ref<HTMLElement>
const customScrollOfId = ref(props.scrollOfId || "")

const ui = useUiStore()

onMounted(() => handleCustomScroll())

const style = computed(() => {
  return [
    "top-0 w-[90%] duration-300 transform transition-transform overflow-hidden",
    "duration-200 transition-all",
    scrollOfStyle(),
  ]
})

watch(
  () => props.scrollOfId,
  value => {
    if (value && customScrollOfId.value !== value) {
      customScrollOfId.value = value
      handleCustomScroll()
    }
  },
  { immediate: true },
)

async function handleCustomScroll() {
  if (props.scrollOfId.length) {
    scrolled.value = false
    lastPosition.value = 0

    const value = await onMountedDOMNode(props.scrollOfId)

    if (el.value.id === value?.id) {
      el.value.removeEventListener("scroll", handleScroll)
      el.value = {} as HTMLElement
    }

    if (value?.id) {
      el.value = value as HTMLElement

      el.value.addEventListener("scroll", handleScroll, {
        passive: true,
      })
    }
  } else
    window.addEventListener("scroll", handleScroll, { passive: true })
}

function handleScroll() {
  if (props.scrollOfId) {
    if (
      lastPosition.value < el.value.scrollTop &&
      limitPosition.value < el.value.scrollTop
    )
      scrolled.value = true

    if (lastPosition.value > el.value.scrollTop)
      scrolled.value = false

    lastPosition.value = el.value.scrollTop
  } else {
    if (
      lastPosition.value < window.scrollY &&
      limitPosition.value < window.scrollY
    )
      scrolled.value = true

    if (lastPosition.value > window.scrollY) scrolled.value = false

    lastPosition.value = window.scrollY
  }
}

function scrollOfStyle() {
  if (props.scrollOf && ui.screenWidth >= 1023) {
    return [
      lastPosition.value > 15 ? props.classOnScroll : "",
      scrolled.value ? "-translate-y-full" : "translate-y-[0%]",
    ]
  } else if (props.scrollOfMobile && ui.screenWidth <= 1023) {
    return [
      lastPosition.value > 15 ? props.classOnScroll : "",
      scrolled.value ? "-translate-y-full" : "translate-y-[0%]",
    ]
  }
}

/** Watch scrollof and emit boolean */
watch(
  () => lastPosition.value,
  value => emit("scrollOf", value > 15),
  { immediate: true },
)

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll)
})
</script>

<template>
  <header :class="style">
    <slot />
  </header>
</template>
